import { MapperBase } from '~/framework/core/mapper';
import { CheckItemEntity } from '~/framework/domain/masters/check-item/checkItemEntity';
import { CheckItem } from '~/graphql/graphQLServerApi';

export class CheckItemMapper extends MapperBase<CheckItem, CheckItemEntity> {
  constructor() {
    super();
  }

  protected instantiateWithData(data: CheckItem): CheckItemEntity {
    return new CheckItemEntity(data.id, data.name, data.default, data.status);
  }

  protected updateWithData(data: CheckItem, entity: CheckItemEntity): void {
    entity.name = data.name;
    entity.default = data.default;
    entity.status = data.status;
  }
}
