import _ from 'lodash';
import { RawScheduleJsonObject } from '~/framework/server-api/schedule/schedule/schedule';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import {
  AggregatedOrderEntity as IOrderEntity,
  PostponeOrderStatus,
} from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { IScheduleSolutionStatus } from '~/framework/server-api/user-setting/userSetting';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { userSetting$getSymbol } from '~/framework/server-api/user-setting/get';
import { driver$getByIdsSymbol, driver$getAllSymbol } from '~/framework/server-api/masters/driver';
import { UserSettingMapper } from '~/framework/domain/user-setting/userSettingMapper';
import { IOrderFactory } from '~/framework/factories/schedule/orderFactory';
import { DriverMapper } from '~/framework/domain/masters/driver/driverMapper';
import { AggregatedCarMapper } from '~/framework/domain/masters/car/aggregatedCarMapper';
import {
  AggregatedBaseSiteMapper,
  IAggregatedBaseSiteMapper,
} from '~/framework/domain/masters/base-site/aggregatedBaseSiteMapper';
import { car$getByIdsSymbol } from '~/framework/server-api/masters/car';
import { CarTypeMapper } from '~/framework/domain/masters/car-type/carTypeMapper';
import { disposalSite$getAllSymbol } from '~/framework/server-api/masters/disposalSite';
import { AggregatedDisposalSiteMapper } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteMapper';
import { IScheduleData, ScheduleData } from '~/pages/schedule/scheduleData';
import {
  RawRouteJsonObject,
  RawRouteJsonObjectWithId,
  RawScheduleInfeasibilityJsonObject,
  RawInconsistencyJsonObject,
  RawInfeasibilityJsonObject,
  ScheduleInfeasibilityOvertimeWorkType,
  RawCollectionJsonObject,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import {
  IOrder,
  IPotentialModification,
  IPotentialModificationDisposalSite,
  IPotentialModificationDriver,
  IPotentialModificationOrder,
} from '~/components/pages/schedule/r-schedule-errors/infeasibility';
import { DriverReason, IDriverReason, IInfeasibility, Infeasibility } from '~/pages/schedule/infeasibility';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { IInconsistency, Inconsistency } from '~/pages/schedule/inconsistency';
import { OrderAcceptanceCheckId } from '~/framework/constants';
import { mapData, mapEntity } from '~/framework/core/mapper';

import { CarUsage } from '~/pages/schedule/carUsage';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { IdGenerator } from '~/framework/core/id';
import { IRoute, Route } from '~/pages/schedule/route';
import { ICreateData } from '~/framework/server-api/schedule/order/order-acceptance-check/create';
import { generationSite$getByIdsSymbol } from '~/framework/server-api/masters/generationSite';
import { client$getByIdsSymbol } from '~/framework/server-api/masters/client';
import { AggregatedClientMapper } from '~/framework/domain/masters/client/aggregatedClientMapper';
import { GenerationSiteMapper } from '~/framework/domain/masters/generation-site/generationSiteMapper';
import { carType$getByIdsSymbol, carType$getAllSymbol } from '~/framework/server-api/masters/carType';

import { baseSite$getAllSymbol } from '~/framework/server-api/masters/baseSite';
import { BaseSiteEntity } from '~/framework/domain/masters/base-site/baseSiteEntity';
import { IAbstractFactory } from '~/framework/domain/schedule/schedule/pseudo-entities/abstractFactory';
import { Collection } from '~/pages/schedule/collection';
import { IOriginalRoute, OriginalRoute } from '~/pages/schedule/originalRoute';
import { Disposal } from '~/pages/schedule/disposal';
import { OriginalCollection } from '~/pages/schedule/originalCollection';
import { IOriginalDisposal, OriginalDisposal } from '~/pages/schedule/originalDisposal';
import { InconsistentRouteInfo } from '~/pages/schedule/inconsistentRouteInfo';

import { containerType$getAllSymbol } from '~/framework/server-api/masters/containerType';
import { defaultRouteSettings } from '~/framework/domain/schedule/schedule/pseudo-entities/routeEntity';
import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import { driverAttendance$getByDateRangeOfAllDriversSymbol } from '~/framework/server-api/masters/driverAttendance';
import { IDriverAttendanceFactory } from '~/framework/factories/masters/driverAttendanceFactory';
import { AggregatedDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { UserSettingEntity } from '~/framework/domain/user-setting/userSettingEntity';
import { assertNumberOfEntities } from '~/framework/assertions';
import { IDriverAssignment, DriverType } from '~/framework/domain/schedule/schedule/pseudo-entities/driverAssignment';
import { convertOrderPlanInputToOrderPlan } from '~/framework/domain/schedule/order/orderUtils';
import { InconsistentReasonType } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistentRouteInfoData';
import {
  DriverScheduleJsonObject,
  ScheduleResponseJsonObject,
} from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';

export interface IScheduleFactory {
  /**
   * 配車表データから配車表ページで使う ViewModel を書き出す
   *
   * @param scheduleId 配車表 ID
   * @param date 配車表の日付、本来的には外から与えるのは微妙なのだが、schedule 自体が持っていないので
   * @param orderGroupId 配車表の配車グループ ID
   * @param scheduleResponse 生の配車表データ(V2)
   */
  buildByDataV2(
    scheduleId: string,
    date: Date,
    orderGroupId: string,
    // V1併用時の臨時措置、データ更新時はundefinedを渡し、その場合はV1データ側のデータ更新をスキップする
    scheduleData: undefined,
    scheduleResponse: ScheduleResponseJsonObject
  ): Promise<IScheduleData>;

  /**
   * 受注チェックで返ってきた infeasibilities をビルドする
   *
   * @param infeasibilityDatas
   * @param input
   * @param scheduleSolutionStatus
   */
  buildOrderAcceptanceCheckInfeasibilities(
    infeasibilityDatas: RawInfeasibilityJsonObject[],
    input: ICreateData,
    scheduleSolutionStatus?: Maybe<IScheduleSolutionStatus>
  ): Promise<IInfeasibility[]>;

  /**
   * 受注チェックで返ってきた inconsistencies をビルドする
   *
   * @param inconsistencyData
   * @param scheduleSolutionStatus
   */
  buildOrderAcceptanceCheckInconsistencies(
    inconsistencyData: RawInconsistencyJsonObject[],
    scheduleSolutionStatus?: Maybe<IScheduleSolutionStatus>
  ): Promise<IInconsistency[]>;
}

export interface IPreparedData<Order extends IOrder> {
  driverMap: Map<string, DriverEntity>;
  orderMap: Map<string, Order>;
  carMap: Map<string, AggregatedCarEntity>;
  carTypeMap: Map<string, CarTypeEntity>;
  disposalSiteMap: Map<string, DisposalSiteEntity>;
  baseSiteMap: Map<string, BaseSiteEntity>;
}

/**
 * 配車表上で使われていて明示的に読み込む必要のあるリソース
 */
export interface IRequiredResources {
  carIdSet: Set<PersistentId>;
  carTypeIdSet: Set<PersistentId>;
  driverIdSet: Set<PersistentId>;
  orderIdSet: Set<PersistentId>;
  disposalSiteIdSet: Set<PersistentId>;
}

type PreparedDataOfInfeasibilities<Order extends IOrder> = Omit<IPreparedData<Order>, 'baseSiteMap'>;

type PreparedDataOfInconsistencies<Order extends IOrder> = Pick<IPreparedData<Order>, 'driverMap' | 'orderMap'>;

const overtimeWorkTypeSnakeToPascalMap = new Map<ScheduleInfeasibilityOvertimeWorkType, OvertimeWorkType>([
  [ScheduleInfeasibilityOvertimeWorkType.None, OvertimeWorkType.None],
  [ScheduleInfeasibilityOvertimeWorkType.Both, OvertimeWorkType.Both],
  [ScheduleInfeasibilityOvertimeWorkType.AvailableInEarlyTime, OvertimeWorkType.AvailableInEarlyTime],
  [ScheduleInfeasibilityOvertimeWorkType.AvailableInLateTime, OvertimeWorkType.AvailableInLateTime],
]);

/**
 * ScheduleData や ScheduleDataEntity を作成する時に必要になるベースの機能をまとめたもの。このクラス単体で利用する
 * 事は想定しないので abstract になっている。現状のところ ScheduleDataEntity を生成するシチュエーションがないので
 * ScheduleData を作成するために利用する事しか想定していない。
 */
abstract class ScheduleFactoryBase {
  protected readonly store: Store;
  protected readonly serverApis: ServerApiManager;
  protected readonly orderFactory: IOrderFactory;
  protected readonly userSettingMapper: UserSettingMapper;
  protected readonly driverMapper: DriverMapper;
  protected readonly carMapper: AggregatedCarMapper;
  protected readonly carTypeMapper: CarTypeMapper;
  protected readonly disposalSiteMapper: AggregatedDisposalSiteMapper;
  protected readonly baseSiteMapper: IAggregatedBaseSiteMapper;
  protected readonly clientMapper: AggregatedClientMapper;
  protected readonly generationSiteMapper: GenerationSiteMapper;

  constructor(store: Store, serverApis: ServerApiManager, orderFactory: IOrderFactory) {
    this.store = store;
    this.serverApis = serverApis;
    this.orderFactory = orderFactory;
    this.userSettingMapper = new UserSettingMapper(this.store.userSetting);
    this.driverMapper = new DriverMapper();
    this.carMapper = new AggregatedCarMapper(
      store.masters.aggregatedCar,
      store.masters.aggregatedCarType,
      store.masters.orderGroup,
      store.masters.aggregatedCarTypeContainerType,
      store.masters.aggregatedBaseSite,
      store.masters.user
    );
    this.carTypeMapper = new CarTypeMapper();
    this.disposalSiteMapper = new AggregatedDisposalSiteMapper(
      this.store.masters.aggregatedDisposalSite,
      this.store.masters.user
    );
    this.baseSiteMapper = new AggregatedBaseSiteMapper(this.store.masters.aggregatedBaseSite, this.store.masters.user);
    this.clientMapper = new AggregatedClientMapper(this.store.masters.aggregatedClient, this.store.masters.user);
    this.generationSiteMapper = new GenerationSiteMapper();
  }

  protected async prepare(
    scheduleData: RawScheduleJsonObject<RawRouteJsonObjectWithId>
  ): Promise<IPreparedData<IOrderEntity>> {
    const driver$getAllApi = this.serverApis.get(driver$getAllSymbol);
    const car$getByApi = this.serverApis.get(car$getByIdsSymbol);
    const carType$getAllApi = this.serverApis.get(carType$getAllSymbol);
    const disposalSite$getAllApi = this.serverApis.get(disposalSite$getAllSymbol);
    const baseSite$getAllApi = this.serverApis.get(baseSite$getAllSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);

    const requiredResources = this.getRequiredResourcesOfSchedule(scheduleData);

    const [driverData, carData, carTypeData, disposalSiteData, baseSiteData, containerTypesData] = await Promise.all([
      driver$getAllApi.getAll(requiredResources.driverIdSet.toArray()),
      car$getByApi.getByIds(requiredResources.carIdSet.toArray()),
      carType$getAllApi.getAll(),
      disposalSite$getAllApi.getAll(),
      baseSite$getAllApi.getAll(),
      containerType$getAllApi.getAll(),
    ]);
    const drivers = this.driverMapper.map(driverData);
    const containerTypeDataMap = mapData(containerTypesData, 'id');
    const carsEntityData = carData.map((car) => {
      const carTypeEntityData = {
        ...car.carType,
        orderGroupId: car.carType.orderGroup.id,
        loadableContainerTypes: car.carType.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };

      return { ...car, carType: carTypeEntityData };
    });
    const cars = this.carMapper.map(carsEntityData);
    const carTypes = this.carTypeMapper.map(
      carTypeData.map((carType) => {
        return {
          ...carType,
          orderGroupId: carType.orderGroup.id,
        };
      })
    );
    const disposalSites = this.disposalSiteMapper.map(disposalSiteData);
    const baseSites = this.baseSiteMapper.map(baseSiteData);

    // order は複雑なので移譲
    const orders = await this.orderFactory.buildByIds(requiredResources.orderIdSet.toArray());

    return {
      driverMap: mapEntity(drivers),
      carMap: mapEntity(cars),
      carTypeMap: mapEntity(carTypes),
      disposalSiteMap: mapEntity(disposalSites),
      orderMap: mapEntity(orders),
      baseSiteMap: mapEntity(baseSites),
    };
  }

  // TODO: scheduleDataはCSVダウンロードで利用するためだけに残している、V1対応が終われば削除する
  protected async prepareV2(
    scheduleResponse: ScheduleResponseJsonObject,
    scheduleData: Maybe<RawScheduleJsonObject<RawRouteJsonObjectWithId>>
  ): Promise<IPreparedData<IOrderEntity>> {
    const driver$getAllApi = this.serverApis.get(driver$getAllSymbol);
    const car$getByApi = this.serverApis.get(car$getByIdsSymbol);
    const carType$getAllApi = this.serverApis.get(carType$getAllSymbol);
    const disposalSite$getAllApi = this.serverApis.get(disposalSite$getAllSymbol);
    const baseSite$getAllApi = this.serverApis.get(baseSite$getAllSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);

    const requiredResources = this.getRequiredResourcesOfScheduleResponse(scheduleResponse);
    // V1データのmisc.original.routesがある場合V2データからは取得不可能なのでV1データもマージする
    if (scheduleData !== undefined) {
      const requiredResourcesOfScheduleData = this.getRequiredResourcesOfSchedule(scheduleData);
      // requiredResourcesにマージ
      requiredResources.driverIdSet.addValues(...requiredResourcesOfScheduleData.driverIdSet.toArray());
      requiredResources.carIdSet.addValues(...requiredResourcesOfScheduleData.carIdSet.toArray());
      requiredResources.carTypeIdSet.addValues(...requiredResourcesOfScheduleData.carTypeIdSet.toArray());
      requiredResources.orderIdSet.addValues(...requiredResourcesOfScheduleData.orderIdSet.toArray());
      requiredResources.disposalSiteIdSet.addValues(...requiredResourcesOfScheduleData.disposalSiteIdSet.toArray());
    }

    const [driverData, carData, carTypeData, disposalSiteData, baseSiteData, containerTypesData] = await Promise.all([
      driver$getAllApi.getAll(requiredResources.driverIdSet.toArray()),
      car$getByApi.getByIds(requiredResources.carIdSet.toArray()),
      carType$getAllApi.getAll(),
      disposalSite$getAllApi.getAll(),
      baseSite$getAllApi.getAll(),
      containerType$getAllApi.getAll(),
    ]);
    const drivers = this.driverMapper.map(driverData);
    const containerTypeDataMap = mapData(containerTypesData, 'id');
    const carsEntityData = carData.map((car) => {
      const carTypeEntityData = {
        ...car.carType,
        orderGroupId: car.carType.orderGroup.id,
        loadableContainerTypes: car.carType.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };

      return { ...car, carType: carTypeEntityData };
    });
    const cars = this.carMapper.map(carsEntityData);
    const carTypes = this.carTypeMapper.map(
      carTypeData.map((carType) => {
        return {
          ...carType,
          orderGroupId: carType.orderGroup.id,
        };
      })
    );
    const disposalSites = this.disposalSiteMapper.map(disposalSiteData);
    const baseSites = this.baseSiteMapper.map(baseSiteData);

    // order は複雑なので移譲
    const orders = await this.orderFactory.buildByIds(requiredResources.orderIdSet.toArray());

    return {
      driverMap: mapEntity(drivers),
      carMap: mapEntity(cars),
      carTypeMap: mapEntity(carTypes),
      disposalSiteMap: mapEntity(disposalSites),
      orderMap: mapEntity(orders),
      baseSiteMap: mapEntity(baseSites),
    };
  }

  /**
   * 配車表データに必要なリソースの情報を取得するV2
   * @param scheduleResponse
   * @private
   */
  private getRequiredResourcesOfScheduleResponse(scheduleResponse: ScheduleResponseJsonObject): IRequiredResources {
    // IScheduleJsonObject から取り出した ID はそのままだと string だが、内部では PseudoId にしないと扱いづらい
    // なのでいったん PseudoId に変換してから PseudoId.value を取り出しているので注意
    const resources: IRequiredResources = {
      carIdSet: new Set<PersistentId>(),
      carTypeIdSet: new Set<PersistentId>(),
      driverIdSet: new Set<PersistentId>(),
      orderIdSet: new Set<PersistentId>(),
      disposalSiteIdSet: new Set<PersistentId>(),
    };

    // driver内のリソースに追加する
    this.addRequiredResourceOfDrivers(scheduleResponse.driver_schedules, resources);

    // 作成不可まわり
    if (scheduleResponse.infeasibilities) {
      this.addRequiredResourceOfInfeasibilities(scheduleResponse.infeasibilities, resources);
    }
    if (scheduleResponse.inconsistencies) {
      this.addRequiredResourceOfInconsistency(scheduleResponse.inconsistencies, resources);
    }

    // not_assigned_driver_idsをdriverIdSetに追加
    resources.driverIdSet.addValues(...scheduleResponse.not_assigned_driver_ids.map((id) => id.toPseudoId().value));
    // not_assigned_order_idsをorderIdSetに追加
    resources.orderIdSet.addValues(...scheduleResponse.not_assigned_order_ids.map((id) => id.toPseudoId().value));

    return resources;
  }

  /**
   * 配車表データに必要なリソースの情報を取得する
   * @param scheduleData
   * @private
   */
  private getRequiredResourcesOfSchedule(
    scheduleData: RawScheduleJsonObject<RawRouteJsonObjectWithId>
  ): IRequiredResources {
    // IScheduleJsonObject から取り出した ID はそのままだと string だが、内部では PseudoId にしないと扱いづらい
    // なのでいったん PseudoId に変換してから PseudoId.value を取り出しているので注意
    const resources: IRequiredResources = {
      carIdSet: new Set<PersistentId>(),
      carTypeIdSet: new Set<PersistentId>(),
      driverIdSet: new Set<PersistentId>(),
      orderIdSet: new Set<PersistentId>(),
      disposalSiteIdSet: new Set<PersistentId>(),
    };

    this.addRequiredResourceOfRoutes(scheduleData.routes, resources);

    // 作成不可まわり
    if (scheduleData.infeasibilities) {
      this.addRequiredResourceOfInfeasibilities(scheduleData.infeasibilities, resources);
    }
    if (scheduleData.inconsistencies) {
      this.addRequiredResourceOfInconsistency(scheduleData.inconsistencies, resources);
    }
    // misc に含まれる編集の原点となった routes（あれば）
    if (scheduleData.misc?.original) {
      this.addRequiredResourceOfRoutes(scheduleData.misc.original.routes, resources);
    }

    return resources;
  }

  /**
   * 配車表データに必要なリソースの情報を取得する
   * @param driverSchedules
   * @private
   */
  private addRequiredResourceOfDrivers(
    driverSchedules: DriverScheduleJsonObject[],
    resources: IRequiredResources
  ): void {
    driverSchedules.forEach((driverSchedule) => {
      // routesは廃止されて上位にdriverの配列があるためdriverIdはそこから取得する
      resources.driverIdSet.addValues(driverSchedule.driver_id.toPseudoId().value);
      // orderIdはroute.order_assignmentsのidから
      resources.orderIdSet.addValues(
        ..._.flatten(driverSchedule.routes.map((route) => route.order_assignments)).map(
          (orderAssignment) => orderAssignment.order_id.toPseudoId().value
        )
      );
      // driver_schedule.routes配列の中のroute.car_idsの配列を一つの配列にまとめる
      let carIds: string[] = [];
      driverSchedule.routes.forEach((route) => {
        if (route.car_ids === undefined) return;
        carIds = carIds.concat(route.car_ids.map((carId) => carId.toPseudoId().value));
      });
      resources.carIdSet.addValues(...carIds);
      resources.disposalSiteIdSet.addValues(
        ..._.flatten(driverSchedule.routes.map((route) => route.disposals)).map(
          (disposalSite) => disposalSite.disposal_site_id.toPseudoId().value
        )
      );
    });
  }

  private addRequiredResourceOfRoutes(routes: RawRouteJsonObject[], resources: IRequiredResources): void {
    resources.driverIdSet.addValues(...routes.map((route) => route.driver_id.toPseudoId().value));
    resources.orderIdSet.addValues(
      ..._.flatten(routes.map((route) => route.collections)).map((collection) => collection.order_id.toPseudoId().value)
    );
    const carIds = routes
      .map((route) => route.car_id?.toPseudoId().value)
      .filter((id) => id !== undefined) as PersistentId[];
    resources.carIdSet.addValues(...carIds);
    resources.disposalSiteIdSet.addValues(
      ..._.flatten(routes.map((route) => route.disposals)).map(
        (disposalSite) => disposalSite.disposal_site_id.toPseudoId().value
      )
    );
  }

  private addRequiredResourceOfInfeasibilities(
    infeasibilities: RawScheduleInfeasibilityJsonObject[],
    resources: IRequiredResources
  ): void {
    for (const infeasibility of infeasibilities) {
      if (infeasibility.assigned_driver_id !== undefined) {
        resources.driverIdSet.add(infeasibility.assigned_driver_id.toPseudoId().value);
      }
      if (infeasibility.driver_reasons !== undefined) {
        for (const driverReason of infeasibility.driver_reasons) {
          resources.driverIdSet.add(driverReason.driver_id.toPseudoId().value);
        }
      }
      if (infeasibility.assigned_car_id !== undefined) {
        resources.carIdSet.add(infeasibility.assigned_car_id.toPseudoId().value);
      }
      if (infeasibility.acceptable_car_type_ids !== undefined) {
        resources.carTypeIdSet.addValues(...infeasibility.acceptable_car_type_ids.map((id) => id.toPseudoId().value));
      }
      resources.orderIdSet.add(infeasibility.order_id.toPseudoId().value);
    }
  }

  private addRequiredResourceOfInconsistency(
    inconsistencies: RawInconsistencyJsonObject[],
    resources: IRequiredResources
  ): void {
    for (const inconsistency of inconsistencies) {
      resources.driverIdSet.add(inconsistency.driver_id.toPseudoId().value);
      if (inconsistency.order_ids !== undefined && inconsistency.order_ids) {
        resources.orderIdSet.addValues(...inconsistency.order_ids.map((id) => id.toPseudoId().value));
      }
    }
  }
}

/**
 * ScheduleData を作成するためのクラス
 */
export class ScheduleFactory
  extends ScheduleFactoryBase
  implements IScheduleFactory, IAbstractFactory<IRoute, Collection>
{
  // 新規ルート作成時に必要になるためキャッシュしている
  // 厳密には全てを保持している必要はない
  private preparedData: IPreparedData<IOrderEntity>;
  private readonly driverAttendanceFactory: IDriverAttendanceFactory;

  constructor(
    store: Store,
    serverApis: ServerApiManager,
    orderFactory: IOrderFactory,
    driverAttendanceFactory: IDriverAttendanceFactory
  ) {
    super(store, serverApis, orderFactory);
    // ビルドした時に詰められるが、Maybe になっていると面倒なのでとりあえずダミーを詰めておく
    this.preparedData = {
      driverMap: new Map(),
      orderMap: new Map(),
      disposalSiteMap: new Map(),
      carTypeMap: new Map(),
      carMap: new Map(),
      baseSiteMap: new Map(),
    };
    this.driverAttendanceFactory = driverAttendanceFactory;
  }

  async buildByDataV2(
    scheduleId: string,
    date: Date,
    orderGroupId: string,
    // FIXME: v1 に関係するロジックを削除する
    scheduleData: Maybe<RawScheduleJsonObject<RawRouteJsonObjectWithId>>,
    scheduleResponse: ScheduleResponseJsonObject
  ): Promise<IScheduleData> {
    const [userSetting, driverAttendances, preparedData] = await Promise.all([
      this.getUserSetting(),
      this.getAllDriverAttendancesOf(date, orderGroupId),
      this.prepareV2(scheduleResponse, undefined),
    ]);
    this.preparedData = preparedData;
    const drivers = [...this.preparedData.driverMap.values()].sort((a, b) => a.id.localeCompare(b.id));
    const cars = [...this.preparedData.carMap.values()].sort((a, b) => a.id.localeCompare(b.id));
    const disposals = [...this.preparedData.disposalSiteMap.values()].sort((a, b) => a.id.localeCompare(b.id));
    const orders = [...this.preparedData.orderMap.values()].sort((a, b) => a.id.localeCompare(b.id));

    // V1データ利用箇所
    let routes: IRoute[] = [];
    let originalRoutes: IOriginalRoute[] = [];
    // scheduleDataがundefinedの場合はV2仕様の配車表操作であるため更新処理を行わない
    if (scheduleData !== undefined) {
      const rawOriginalRoutes = scheduleData.misc?.original.routes ?? scheduleData.routes;
      const assignmentMap = AssignmentMapBuilder.build(rawOriginalRoutes, this.preparedData);
      originalRoutes = this.buildOriginalRoutes(rawOriginalRoutes, this.preparedData, assignmentMap);
      routes = this.buildRoutes(scheduleData.routes, originalRoutes, this.preparedData, assignmentMap);
    }

    const scheduleSolutionStatus = userSetting.getScheduleSolutionStatusOf(scheduleId);
    const inconsistencies =
      scheduleResponse.inconsistencies === undefined
        ? undefined
        : this.buildInconsistencies(scheduleResponse.inconsistencies, this.preparedData, scheduleSolutionStatus);
    const infeasibilities =
      scheduleResponse.infeasibilities === undefined
        ? undefined
        : this.buildInfeasibilities(scheduleResponse.infeasibilities, this.preparedData, scheduleSolutionStatus);

    const entity = new ScheduleData(
      IdGenerator.generateNewId(),
      this,
      routes,
      originalRoutes,
      inconsistencies,
      infeasibilities,
      drivers,
      driverAttendances,
      cars,
      disposals,
      orders
    );
    return entity;
  }

  async buildOrderAcceptanceCheckInconsistencies(
    inconsistencyData: RawInconsistencyJsonObject[],
    scheduleSolutionStatus?: Maybe<IScheduleSolutionStatus>
  ): Promise<IInconsistency[]> {
    const driver$getByIdsApi = this.serverApis.get(driver$getByIdsSymbol);
    const driverIdSet: Set<PersistentId> = new Set<PersistentId>();
    const orderIdSet: Set<PersistentId> = new Set<PersistentId>();
    for (const inconsistency of inconsistencyData) {
      driverIdSet.add(inconsistency.driver_id.toPseudoId().value);
      if (inconsistency.order_ids !== undefined && inconsistency.order_ids) {
        orderIdSet.addValues(...inconsistency.order_ids.map((id) => id.toPseudoId().value));
      }
    }
    const driverIds = driverIdSet.toArray();
    const orderIds = orderIdSet.toArray();
    const driverData = await driver$getByIdsApi.getByIds(driverIds);
    const driverEntities = this.driverMapper.map(driverData);
    const orderEntities = await this.orderFactory.buildByIds(orderIds);
    assertNumberOfEntities(driverIds, driverEntities);
    assertNumberOfEntities(orderIds, orderEntities);
    const preparedData: PreparedDataOfInconsistencies<IOrderEntity> = {
      driverMap: mapEntity(driverEntities),
      orderMap: mapEntity(orderEntities),
    };
    return this.buildInconsistencies(inconsistencyData, preparedData, scheduleSolutionStatus);
  }

  async buildOrderAcceptanceCheckInfeasibilities(
    infeasibilityDatas: RawScheduleInfeasibilityJsonObject[],
    input: ICreateData,
    scheduleSolutionStatus?: Maybe<IScheduleSolutionStatus>
  ): Promise<IInfeasibility[]> {
    const driver$getByIdsApi = this.serverApis.get(driver$getByIdsSymbol);
    const car$getByIdsApi = this.serverApis.get(car$getByIdsSymbol);
    const carType$getByIdsApi = this.serverApis.get(carType$getByIdsSymbol);
    const generationSite$getByIdsApi = this.serverApis.get(generationSite$getByIdsSymbol);
    const disposalSite$getAll = this.serverApis.get(disposalSite$getAllSymbol);

    const client$getByIdsApi = this.serverApis.get(client$getByIdsSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);

    const driverIdSet: Set<PersistentId> = new Set<PersistentId>();
    const carIdSet: Set<PersistentId> = new Set<PersistentId>();
    const carTypeIdSet: Set<PersistentId> = new Set<PersistentId>();
    const orderIdSet: Set<PersistentId> = new Set<PersistentId>();
    const clientIdSet: Set<PersistentId> = new Set<PersistentId>();
    const generationSiteIdSet: Set<PersistentId> = new Set<PersistentId>();

    for (const infeasibility of infeasibilityDatas) {
      if (infeasibility.assigned_driver_id !== undefined) {
        driverIdSet.add(infeasibility.assigned_driver_id.toPseudoId().value);
      }
      if (infeasibility.driver_reasons !== undefined) {
        for (const driverReason of infeasibility.driver_reasons) {
          driverIdSet.add(driverReason.driver_id.toPseudoId().value);
        }
      }
      if (infeasibility.assigned_car_id !== undefined) {
        carIdSet.add(infeasibility.assigned_car_id.toPseudoId().value);
      }
      if (infeasibility.acceptable_car_type_ids !== undefined) {
        carTypeIdSet.addValues(...infeasibility.acceptable_car_type_ids.map((id) => id.toPseudoId().value));
      }
      if (infeasibility.assignable_car_type_ids !== undefined) {
        carTypeIdSet.addValues(...infeasibility.assignable_car_type_ids.map((id) => id.toPseudoId().value));
      }
      if (infeasibility.potential_modifications !== undefined) {
        infeasibility.potential_modifications.forEach((modification) => {
          // modificationには、disposal_sitesも存在するが、disposalSiteは全データ取得しているので、不要
          if (modification.drivers !== undefined) {
            driverIdSet.addValues(...modification.drivers.map((driver) => driver.driver_id.toPseudoId().value));
          }
          if (modification.orders !== undefined) {
            orderIdSet.addValues(...modification.orders.map((order) => order.order_id.toPseudoId().value));
          }
        });
      }
      orderIdSet.add(infeasibility.order_id.toPseudoId().value);
    }

    // OrderAcceptanceCheckId は仮の ID なのでビルドしようとしてもできない、なのでここで削っておく。
    // 当初、add する側で絞るやり方をしていたがそれだと書き方によって漏れる可能性があるので、
    // ここで統一して削っておく事にした。
    orderIdSet.delete(OrderAcceptanceCheckId);

    clientIdSet.add(input.clientId);
    generationSiteIdSet.add(input.generationSiteId);

    const driverIds = driverIdSet.toArray();
    const orderIds = orderIdSet.toArray();
    const clientIds = clientIdSet.toArray();
    const generationSiteIds = generationSiteIdSet.toArray();
    const carIds = carIdSet.toArray();
    const carTypeIds = carTypeIdSet.toArray();
    const [carData, carTypeData, driverData, clientData, generationSiteData, disposalSiteData, containerTypesData] =
      await Promise.all([
        car$getByIdsApi.getByIds(carIds),
        carType$getByIdsApi.getByIds(carTypeIds),
        driver$getByIdsApi.getByIds(driverIds),
        client$getByIdsApi.getByIds(clientIds),
        generationSite$getByIdsApi.getByIds(generationSiteIds),
        disposalSite$getAll.getAll(),
        containerType$getAllApi.getAll(),
      ]);

    const containerTypeDataMap = mapData(containerTypesData, 'id');
    const carsEntityData = carData.map((car) => {
      const carTypeEntityData = {
        ...car.carType,
        orderGroupId: car.carType.orderGroup.id,
        loadableContainerTypes: car.carType.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };

      return { ...car, carType: carTypeEntityData };
    });

    const carEntities = this.carMapper.map(carsEntityData);
    const carTypeEntities = this.carTypeMapper.map(
      carTypeData.map((carType) => {
        return {
          ...carType,
          orderGroupId: carType.orderGroup.id,
        };
      })
    );
    const driverEntities = this.driverMapper.map(driverData);
    const clientEntities = this.clientMapper.map(clientData);
    const generationSiteEntities = this.generationSiteMapper.map(generationSiteData);
    const disposalSiteEntities = this.disposalSiteMapper.map(disposalSiteData);
    const orderEntities = await this.orderFactory.buildByIds(orderIds);
    assertNumberOfEntities(driverIds, driverEntities);
    assertNumberOfEntities(orderIds, orderEntities);
    assertNumberOfEntities(clientIds, clientEntities);
    assertNumberOfEntities(generationSiteIds, generationSiteEntities);
    assertNumberOfEntities(carTypeIds, carTypeEntities);
    assertNumberOfEntities(carIds, carEntities);
    const clientMap = mapEntity(clientEntities);
    const generationSiteMap = mapEntity(generationSiteEntities);
    const orderMap: Map<PersistentId, IOrder> = mapEntity(orderEntities);
    if (input.id === OrderAcceptanceCheckId) {
      // もし新規受注チェックの場合には ID がまだ存在しない Order になるので、その場合は
      // IOrder のみ満たすオブジェクトをとりあえず詰めておく
      orderMap.set(OrderAcceptanceCheckId, {
        client: clientMap.getOrError(input.clientId),
        date: input.date,
        plan: convertOrderPlanInputToOrderPlan(input.plan),
        collectablePeriodStart: input.collectablePeriodStart,
        collectablePeriodEnd: input.collectablePeriodEnd,
        generationSite: generationSiteMap.getOrError(input.generationSiteId),
        preloadStatus: input.preloadStatus,
        unloadDate: input.unloadDate,
        postponeOrderStatus: PostponeOrderStatus.Default,
      });
    }
    const preparedData: PreparedDataOfInfeasibilities<IOrder> = {
      driverMap: mapEntity(driverEntities),
      orderMap,
      carMap: mapEntity(carEntities),
      carTypeMap: mapEntity(carTypeEntities),
      disposalSiteMap: mapEntity(disposalSiteEntities),
    };
    return this.buildInfeasibilities(infeasibilityDatas, preparedData, scheduleSolutionStatus);
  }

  createRoute(driverId: PersistentId, collections: Collection[]): IRoute {
    const id = IdGenerator.generateNewId();
    const pseudoDriverId = new PseudoId('driver', driverId);
    const driver = this.preparedData.driverMap.getOrError(driverId);
    // endTime が入っていないとルートの必要時間と回収の時間が矛盾する事になってしまって
    // その後の計算ができなくなるのでひとまずルートの必要時間は回収の必要時間の合計という
    // 事にしている。後々無理が出てきそうな気がしなくもないが。。。
    const collectionDuration = collections.reduce((value, collection) => {
      return value + (collection.original?.generationSiteDuration ?? 0);
    }, 0);
    const endTime = defaultRouteSettings.generationSiteArrivalTimeDiff + collectionDuration;
    return new Route(
      undefined,
      id,
      0,
      undefined,
      undefined,
      pseudoDriverId,
      undefined,
      undefined,
      0,
      endTime,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      false,
      collections,
      [],
      [],
      [],
      undefined,
      driver,
      undefined,
      undefined,
      undefined
    );
  }

  private async getUserSetting(): Promise<UserSettingEntity> {
    const userSetting$getApi = this.serverApis.get(userSetting$getSymbol);
    const userSettingData = await userSetting$getApi.get();
    return this.userSettingMapper.mapSingle(userSettingData);
  }

  private async getAllDriverAttendancesOf(
    date: Date,
    orderGroupId: string
  ): Promise<AggregatedDriverAttendanceEntity[]> {
    const driverAttendance$getByDateRangeOfAllDriversApi = this.serverApis.get(
      driverAttendance$getByDateRangeOfAllDriversSymbol
    );
    const driverAttendanceData = await driverAttendance$getByDateRangeOfAllDriversApi.getByDateRangeOfAllDrivers(
      date,
      date
    );
    return (await this.driverAttendanceFactory.buildByData(driverAttendanceData)).filter(
      (driverAttendance) => driverAttendance.primaryCar.carType.orderGroupId === orderGroupId
    );
  }

  /**
   * 生の JSON データから IOriginalRoute を返す
   * @param routes 生の JSON データ
   */
  private buildOriginalRoutes(
    routes: RawRouteJsonObjectWithId[],
    preparedData: IPreparedData<IOrderEntity>,
    assignmentMap: IAssignmentMap
  ): IOriginalRoute[] {
    const { driverMap, carMap, carTypeMap, baseSiteMap, orderMap, disposalSiteMap } = preparedData;
    const entities: IOriginalRoute[] = [];
    for (const route of routes) {
      const pseudoDriverId = route.driver_id.toPseudoId();
      const pseudoCarId = route.car_id?.toPseudoId();
      const carIndex = route.car_index;
      const driver = driverMap.getOrError(pseudoDriverId.value);
      const car = pseudoCarId?.value.map(carMap);
      const carType = car?.carType.id.map(carTypeMap);
      const routeAssignment =
        pseudoCarId !== undefined && carIndex !== undefined
          ? assignmentMap.getAssignmentOfRoute(pseudoCarId.value, carIndex)
          : undefined;
      const baseSite = route.base_site_id?.toPseudoId().value.map(baseSiteMap);

      const originalCollections: OriginalCollection[] = [];
      for (const collection of route.collections) {
        const orderCarUsages = assignmentMap.getAssignmentOfOrder(collection.order_id.toPseudoId().value);
        // 作成不可受注がorderMapに入っていない為undefinedの可能性がある
        const orderEntity = orderMap.get(collection.order_id.toPseudoId().value);
        if (orderEntity) {
          originalCollections.push(
            new OriginalCollection(
              collection._id,
              collection.index,
              collection.order_id.toPseudoId(),
              collection.generation_site_arrival_time,
              collection.generation_site_departure_time,
              collection.has_rest_before_generation_site_arrival,
              this.isFixedAssignmentOfCollectionForV5(route, collection),
              orderMap.getOrError(collection.order_id.toPseudoId().value),
              orderCarUsages
            )
          );
        }
      }

      const originalDisposals: IOriginalDisposal[] = [];
      for (const disposal of route.disposals) {
        originalDisposals.push(
          new OriginalDisposal(
            disposal._id,
            disposal.index,
            disposal.disposal_site_id.toPseudoId(),
            disposal.disposal_site_arrival_time,
            disposal.disposal_site_departure_time,
            disposal.has_rest_before_disposal_site_arrival,
            disposal.prioritize_assigned_disposal_site,
            disposal.sequential_assigned_disposal_site,
            disposalSiteMap.getOrError(disposal.disposal_site_id.toPseudoId().value)
          )
        );
      }

      const inconsistentLoadingRouteInfos: InconsistentRouteInfo[] = [];
      if (route.inconsistent_loading_route_infos !== undefined) {
        for (const inconsistentLoadingRouteInfo of route.inconsistent_loading_route_infos) {
          const driverId = inconsistentLoadingRouteInfo.driver_id.toPseudoId();
          inconsistentLoadingRouteInfos.push(
            new InconsistentRouteInfo(
              inconsistentLoadingRouteInfo.index,
              driverId,
              driverMap.getOrError(driverId.value),
              // NOTE: type がない場合は IsFixedSchedule とみなす
              inconsistentLoadingRouteInfo.type === 'routing_group'
                ? InconsistentReasonType.RoutingGroup
                : InconsistentReasonType.IsFixedSchedule
            )
          );
        }
      }

      const inconsistentTimeRouteInfos: InconsistentRouteInfo[] = [];
      if (route.inconsistent_time_route_infos !== undefined) {
        for (const inconsistentTimeRouteInfo of route.inconsistent_time_route_infos) {
          const driverId = inconsistentTimeRouteInfo.driver_id.toPseudoId();
          inconsistentTimeRouteInfos.push(
            new InconsistentRouteInfo(
              inconsistentTimeRouteInfo.index,
              driverId,
              driverMap.getOrError(driverId.value),
              // NOTE: type がない場合は IsFixedSchedule とみなす
              inconsistentTimeRouteInfo.type === 'routing_group'
                ? InconsistentReasonType.RoutingGroup
                : InconsistentReasonType.IsFixedSchedule
            )
          );
        }
      }

      const entity = new OriginalRoute(
        route._id,
        route.index,
        pseudoCarId,
        route.car_index,
        pseudoDriverId,
        route.is_driver,
        route.is_helper,
        route.start_time,
        route.end_time,
        route.garage_site_departure_id?.toPseudoId(),
        route.garage_site_departure_time,
        route.garage_site_arrival_id?.toPseudoId(),
        route.garage_site_arrival_time,
        route.has_rest_before_garage_site_arrival,
        undefined, // 表示時に更新するため、生成時はundefinedにする
        route.base_site_id?.toPseudoId(),
        route.base_site_arrival_time,
        route.base_site_departure_time,
        route.has_rest_before_base_site_arrival,
        route.has_rest_after_generation_site_departure,
        route.has_rest_after_disposal_site_departure,
        this.isFixedAssignmentOfRouteForV5(route),
        route.is_fixed_schedule,
        originalCollections,
        originalDisposals,
        inconsistentLoadingRouteInfos,
        inconsistentTimeRouteInfos,
        routeAssignment,
        driver,
        car,
        carType,
        baseSite
      );
      entities.push(entity);
    }
    return entities;
  }

  private buildRoutes(
    routes: RawRouteJsonObjectWithId[],
    originalRoutes: IOriginalRoute[],
    preparedData: IPreparedData<IOrderEntity>,
    assignmentMap: IAssignmentMap
  ): IRoute[] {
    const { orderMap, driverMap, carMap, carTypeMap, disposalSiteMap, baseSiteMap } = preparedData;
    const entities: IRoute[] = [];
    const originalRouteMap = new Map(originalRoutes.map((route) => [route.id, route]));
    const originalCollectionMap = new Map(
      _.flatten(originalRoutes.map((route) => route.collections)).map((collection) => [collection.id, collection])
    );
    const originalDisposalMap = new Map(
      _.flatten(originalRoutes.map((route) => route.disposals)).map((disposal) => [disposal.id, disposal])
    );
    for (const route of routes) {
      const pseudoDriverId = route.driver_id.toPseudoId();
      const pseudoCarId = route.car_id?.toPseudoId();
      const carIndex = route.car_index;
      const driver = driverMap.getOrError(pseudoDriverId.value);
      const car = pseudoCarId?.value.map(carMap);
      const carType = car?.carType.id.map(carTypeMap);
      const routeAssignment =
        pseudoCarId !== undefined && carIndex !== undefined
          ? assignmentMap.getAssignmentOfRoute(pseudoCarId.value, carIndex)
          : undefined;
      const baseSite = route.base_site_id?.toPseudoId().value.map(baseSiteMap);

      const collections: Collection[] = [];
      for (const collection of route.collections) {
        let originalCollection = originalCollectionMap.get(collection._id);
        // 作成不可由来の受注を配車表に組み込んだまま再作成がStep12でひっかかった場合にoriginalCollectionが取得できｓない場合がある
        // 取得できなかった場合は作成不可移動の新規作成のOriginalCollectionと同じデフォルトの値でOriginalCollectionを生成する
        // collection.order_idはopt記述のidなのでRIN側のデータ形式に変換する
        const order = orderMap.get(collection.order_id.toPseudoId().value);
        if (originalCollection === undefined && order) {
          // collectionのidはFE側のユニーク値なので、作成不可で入っているCollectionと同じで良いはず
          originalCollection = new OriginalCollection(
            collection._id,
            0,
            collection.order_id.toPseudoId(),
            36000,
            39600,
            false,
            false,
            order,
            []
          );
        }

        if (order) {
          collections.push(
            new Collection(
              originalCollection,
              collection._id,
              collection.index,
              collection.order_id.toPseudoId(),
              collection.generation_site_arrival_time,
              collection.generation_site_departure_time,
              collection.has_rest_before_generation_site_arrival,
              this.isFixedAssignmentOfCollectionForV5(route, collection),
              orderMap.getOrError(collection.order_id.toPseudoId().value),
              assignmentMap.getAssignmentOfOrder(collection.order_id.toPseudoId().value)
            )
          );
        }
      }

      const disposals: Disposal[] = [];
      for (const disposal of route.disposals) {
        disposals.push(
          new Disposal(
            originalDisposalMap.getOrError(disposal._id),
            disposal._id,
            disposal.index,
            disposal.disposal_site_id.toPseudoId(),
            disposal.disposal_site_arrival_time,
            disposal.disposal_site_departure_time,
            disposal.has_rest_before_disposal_site_arrival,
            disposal.prioritize_assigned_disposal_site,
            disposal.sequential_assigned_disposal_site,
            disposalSiteMap.getOrError(disposal.disposal_site_id.toPseudoId().value)
          )
        );
      }

      const inconsistentLoadingRouteInfos: InconsistentRouteInfo[] = [];
      if (route.inconsistent_loading_route_infos !== undefined) {
        for (const inconsistentLoadingRouteInfo of route.inconsistent_loading_route_infos) {
          const driverId = inconsistentLoadingRouteInfo.driver_id.toPseudoId();
          inconsistentLoadingRouteInfos.push(
            new InconsistentRouteInfo(
              inconsistentLoadingRouteInfo.index,
              driverId,
              driverMap.getOrError(driverId.value),
              // NOTE: type がない場合は IsFixedSchedule とみなす
              inconsistentLoadingRouteInfo.type === 'routing_group'
                ? InconsistentReasonType.RoutingGroup
                : InconsistentReasonType.IsFixedSchedule
            )
          );
        }
      }

      const inconsistentTimeRouteInfos: InconsistentRouteInfo[] = [];
      if (route.inconsistent_time_route_infos !== undefined) {
        for (const inconsistentTimeRouteInfo of route.inconsistent_time_route_infos) {
          const driverId = inconsistentTimeRouteInfo.driver_id.toPseudoId();
          inconsistentTimeRouteInfos.push(
            new InconsistentRouteInfo(
              inconsistentTimeRouteInfo.index,
              driverId,
              driverMap.getOrError(driverId.value),
              // NOTE: type がない場合は IsFixedSchedule とみなす
              inconsistentTimeRouteInfo.type === 'routing_group'
                ? InconsistentReasonType.RoutingGroup
                : InconsistentReasonType.IsFixedSchedule
            )
          );
        }
      }

      const entity = new Route(
        originalRouteMap.get(route._id),
        route._id,
        route.index,
        pseudoCarId,
        route.car_index,
        pseudoDriverId,
        route.is_driver,
        route.is_helper,
        route.start_time,
        route.end_time,
        route.garage_site_departure_id?.toPseudoId(),
        route.garage_site_departure_time,
        route.garage_site_arrival_id?.toPseudoId(),
        route.garage_site_arrival_time,
        route.has_rest_before_garage_site_arrival,
        undefined, // 表示時に更新するため、生成時はundefinedにする
        route.base_site_id?.toPseudoId(),
        route.base_site_arrival_time,
        route.base_site_departure_time,
        route.has_rest_before_base_site_arrival,
        route.has_rest_after_generation_site_departure,
        route.has_rest_after_disposal_site_departure,
        this.isFixedAssignmentOfRouteForV5(route),
        route.is_fixed_schedule,
        collections,
        disposals,
        inconsistentLoadingRouteInfos,
        inconsistentTimeRouteInfos,
        routeAssignment,
        driver,
        car,
        carType,
        baseSite
      );
      entities.push(entity);
    }
    return entities;
  }

  private buildInconsistencies(
    inconsistencyDatas: RawInconsistencyJsonObject[],
    preparedData: PreparedDataOfInconsistencies<IOrderEntity>,
    scheduleSolutionStatus: Maybe<IScheduleSolutionStatus> = undefined
  ): IInconsistency[] {
    const { driverMap, orderMap } = preparedData;
    const inconsistencies: IInconsistency[] = [];
    for (const [index, data] of inconsistencyDatas.entries()) {
      const id = IdGenerator.generateNewId();
      const driverId = data.driver_id.toPseudoId();
      const orders = data.order_ids?.map((id) => orderMap.getOrError(id.toPseudoId().value));
      inconsistencies.push(
        new Inconsistency(
          id,
          index,
          scheduleSolutionStatus ? scheduleSolutionStatus.inconsistencies.has(index) : false,
          driverId,
          data.indexes,
          data.reason,
          driverMap.getOrError(driverId.value),
          orders
        )
      );
    }
    return inconsistencies;
  }

  private buildInfeasibilities<Order extends IOrder>(
    infeasibilityDatas: RawScheduleInfeasibilityJsonObject[],
    preparedData: PreparedDataOfInfeasibilities<Order>,
    scheduleSolutionStatus: Maybe<IScheduleSolutionStatus> = undefined
  ): IInfeasibility[] {
    const { driverMap, carMap, carTypeMap, orderMap, disposalSiteMap } = preparedData;
    const infeasibilities: IInfeasibility[] = [];
    for (const [index, data] of infeasibilityDatas.entries()) {
      // 先に DriverReason を生成しておく
      let driverReasons: Maybe<IDriverReason[]>;
      if (data.driver_reasons) {
        driverReasons = [];
        for (const driverReasonData of data.driver_reasons) {
          const driverId = driverReasonData.driver_id.toPseudoId();
          driverReasons.push(new DriverReason(driverId, driverReasonData.reasons, driverMap.get(driverId.value)!));
        }
      }

      const id = IdGenerator.generateNewId();
      const order = orderMap.get(data.order_id.toPseudoId().value)!;
      const assignedDriverId: Maybe<PseudoId> = data.assigned_driver_id?.toPseudoId();
      const assignedCarId: Maybe<PseudoId> = data.assigned_car_id?.toPseudoId();
      const acceptableCarTypeIds: Maybe<PseudoId[]> = data.acceptable_car_type_ids?.map((id) => id.toPseudoId());
      const assignableCarTypeIds: Maybe<PseudoId[]> = data.assignable_car_type_ids?.map((id) => id.toPseudoId());

      const assignedDriver: Maybe<DriverEntity> =
        assignedDriverId === undefined ? undefined : driverMap.get(assignedDriverId.value);
      const assignedCar: Maybe<AggregatedCarEntity> =
        assignedCarId === undefined ? undefined : carMap.get(assignedCarId.value);

      const acceptableCarTypes: Maybe<CarTypeEntity[]> =
        acceptableCarTypeIds === undefined ? undefined : acceptableCarTypeIds.map((id) => carTypeMap.get(id.value)!);
      const assignableCarTypes: Maybe<CarTypeEntity[]> =
        assignableCarTypeIds === undefined ? undefined : assignableCarTypeIds.map((id) => carTypeMap.get(id.value)!);

      const potentialModifications: Maybe<IPotentialModification[]> =
        data.potential_modifications === undefined
          ? undefined
          : data.potential_modifications.map((modification) => {
              return {
                drivers:
                  modification.drivers === undefined
                    ? undefined
                    : modification.drivers.map((driver) => {
                        return {
                          ...driverMap.getOrError(driver.driver_id.toPseudoId().value),
                          regularWorkPeriodStart: driver.regular_work_period_start,
                          regularWorkPeriodEnd: driver.regular_work_period_end,
                          overtimeWorkType: overtimeWorkTypeSnakeToPascalMap.getOrError(driver.overtime_work_type),
                          overtimeWorkableDuration: driver.overtime_workable_duration,
                          idealOvertimeWorkType:
                            driver.ideal_overtime_work_type === undefined
                              ? undefined
                              : overtimeWorkTypeSnakeToPascalMap.get(driver.ideal_overtime_work_type),
                          idealOvertimeWorkableDuration: driver.ideal_overtime_workable_duration,
                        } as IPotentialModificationDriver;
                      }),
                orders:
                  modification.orders === undefined
                    ? undefined
                    : modification.orders.map((order) => {
                        return {
                          ...orderMap.getOrError(order.order_id.toPseudoId().value),
                          orderId: order.order_id.toPseudoId(),
                          collectablePeriodStart: order.collectable_period_start,
                          collectablePeriodEnd: order.collectable_period_end,
                          idealArrivalTime: order.ideal_arrival_time,
                        } as IPotentialModificationOrder;
                      }),
                disposalSites:
                  modification.disposal_sites === undefined
                    ? undefined
                    : modification.disposal_sites.map((disposalSite) => {
                        // NOTE: 過去のデータには disposalSite.order_id がない場合があるので、その場合は infeasibility の order_id を使う
                        const targetOrderId: PseudoId = disposalSite.order_id
                          ? disposalSite.order_id.toPseudoId()
                          : data.order_id.toPseudoId();
                        return {
                          ...disposalSiteMap.getOrError(disposalSite.site_id.toPseudoId().value),
                          orderId: targetOrderId,
                          // NOTE: getOrError すると Order が返ってくるが、 IOrder が満たされていればよいので as IOrder とする
                          order: orderMap.getOrError(targetOrderId.value) as IOrder,
                          disposablePeriodStart: disposalSite.disposable_period_start,
                          disposablePeriodEnd: disposalSite.disposable_period_end,
                          idealArrivalTime: disposalSite.ideal_arrival_time,
                          attendance: disposalSite.attendance
                            ? {
                                disposablePeriodStart: disposalSite.attendance.disposable_period_start,
                                disposablePeriodEnd: disposalSite.attendance.disposable_period_end,
                                idealArrivalTime: disposalSite.attendance.ideal_arrival_time,
                              }
                            : undefined,
                        } as IPotentialModificationDisposalSite;
                      }),
              };
            });

      infeasibilities.push(
        new Infeasibility(
          id,
          index,
          scheduleSolutionStatus ? scheduleSolutionStatus.infeasibilities.has(index) : false,
          PseudoId.buildByCombinedId(data.order_id),
          data.cause,
          data.reasons,
          driverReasons,
          assignedDriverId,
          assignedCarId,
          acceptableCarTypeIds,
          order,
          assignedDriver,
          assignedCar,
          acceptableCarTypes,
          data.type,
          assignableCarTypeIds,
          data.release_driver_assignment,
          data.duration_at_generation_site,
          data.duration_at_disposal_site,
          data.duration_of_driving,
          data.reducible_duration_by_highway,
          assignableCarTypes,
          potentialModifications
        )
      );
    }

    return infeasibilities;
  }

  /**
   * v5の配車表に合わせるため、回収の順番固定有無をルートから算出
   *
   * @description
   *
   * 配車表JSON v5 へのアップグレードによる実装
   *
   * 変更点:
   * - route.is_fixed_assignment 削除（しばらく存続）
   * - collection.is_fixed_assignment 追加
   *
   * 説明:
   * 古い配車表では、回収は is_fixed_assignment を持たないので、
   * ルートの is_fixed_assignment の値から回収の順番固定有無を算出する。
   *
   * @param route ルートJSON
   * @param collection 回収JSON
   * @returns 回収固定有無
   */
  private isFixedAssignmentOfCollectionForV5(route: RawRouteJsonObject, collection: RawCollectionJsonObject): boolean {
    return collection.is_fixed_assignment === undefined ? !!route.is_fixed_assignment : collection.is_fixed_assignment;
  }

  /**
   * v5の配車表に合わせるため、ルートの順番固定有無をそのルートに紐づく回収から算出
   *
   * @description
   *
   * 配車表JSON v5 へのアップグレードによる実装
   *
   * 変更点:
   * - route.is_fixed_assignment 削除（しばらく存続）
   * - collection.is_fixed_assignment 追加
   *
   * 説明:
   * 新しい配車表では、ルートは is_fixed_assignment フィールドを持たないので、
   * それぞれのルートに紐づく回収の is_fixed_assignment の値からルートの順番固定有無を算出する。
   *
   * @param route ルート
   * @returns ルート順番固定有無
   */
  private isFixedAssignmentOfRouteForV5(route: RawRouteJsonObject): boolean {
    const hasIsFixedAssignmentInRoute = route.is_fixed_assignment !== undefined;
    const hasIsFixedAssignmentInCollection = route.collections.some(
      (collection) => collection.is_fixed_assignment !== undefined
    );

    // ルートにも回収にも順番固定有無のフィールドがないことはあり得ない
    if (!hasIsFixedAssignmentInRoute && !hasIsFixedAssignmentInCollection) {
      throw new Error('Json schema error! No is_fixed_assignment field found in both routes and collections.');
    }

    // 旧バージョンの対応 (route.is_fixed_assignment が存在していて、collection.is_fixed_assignment が存在しない)
    if (hasIsFixedAssignmentInRoute && !hasIsFixedAssignmentInCollection) {
      // ルートの順番固定有無をそのまま返す
      return !!route.is_fixed_assignment;
    }

    // 新バージョンの対応（collection.is_fixed_assignment が存在する。route.is_fixed_assignmentは存在してもしなくても関係ない）
    // ルートに紐づく回収が全て順固定されているかを返す
    return route.collections.every((collection) => !!collection.is_fixed_assignment);
  }
}

/**
 * CarId-CarIndex の string
 */
type CarIndexId = string;

const getCarIndexIdOf = (carId: PersistentId, carIndex: number): CarIndexId => {
  return `${carId}-${carIndex}`;
};

/**
 * 車への人の割り当て、車のオーダーへの割り当てのマップを作るためのやつ
 * 結果としては ICarUsage を使ったものを書き出す
 */
class AssignmentMapBuilder {
  private readonly preparedData: IPreparedData<IOrderEntity>;

  /**
   * carId, carIndex, driverIds のマップ
   * 同じ用途の車に乗っている複数の乗務員の ID を管理する
   * carId は PseudoId の string 表現になっている事に注意
   */
  private readonly carDriverMap: Map<string, Map<number, IDriverAssignment[]>>;

  /**
   * orderId, carIndexes のマップ
   * 同じ受注に割り当てられた複数の車の ID を管理する
   * carId は PseudoId の string 表現になっている事に注意
   */
  private readonly orderCarIndexMap: Map<string, Set<CarIndexId>>;

  /**
   * ビルド対象のルート
   */
  private readonly routes: RawRouteJsonObject[];

  constructor(routes: RawRouteJsonObject[], preparedData: IPreparedData<IOrderEntity>) {
    this.preparedData = preparedData;
    this.routes = routes;
    this.carDriverMap = new Map<string, Map<number, IDriverAssignment[]>>();
    this.orderCarIndexMap = new Map<string, Set<CarIndexId>>();
  }

  static build(routes: RawRouteJsonObject[], preparedData: IPreparedData<IOrderEntity>): IAssignmentMap {
    const builder = new AssignmentMapBuilder(routes, preparedData);
    return builder.getAssignmentMap();
  }

  getAssignmentMap(): IAssignmentMap {
    for (const route of this.routes) {
      if (route.car_id === undefined || route.car_index === undefined) continue;
      const driverAssignments = this.getDriverAssignmentOf(route.car_id, route.car_index);
      // NOTE: is_driver === true && is_helper === true となる状態はありえないのでエラー
      if (route.is_driver && route.is_helper) {
        throw new Error(`Unexpected crew assignment is specified, ${route}`);
      }
      // NOTE: route に運転者、補助員の指定がない場合は指定なし (None) として扱う
      const driverType = route.is_driver ? DriverType.Driver : route.is_helper ? DriverType.Helper : DriverType.None;
      driverAssignments.push({
        driverType,
        driverId: route.driver_id.toPseudoId(),
      });
      for (const collection of route.collections) {
        const carIndexes = this.getCarIndexesOf(collection.order_id);
        const carIndexId = this.getCarIndexIdOf(route.car_id, route.car_index);
        carIndexes.add(carIndexId);
      }
    }

    const carDriverMap: Map<CarIndexId, CarUsage> = new Map<CarIndexId, CarUsage>();
    for (const [carId, carIndexMap] of this.carDriverMap) {
      const carPseudoId = carId.toPseudoId();
      for (const [carIndex, driverAssignments] of carIndexMap) {
        const drivers = driverAssignments.map((driverAssignment) =>
          this.preparedData.driverMap.getOrError(driverAssignment.driverId.value)
        );
        const car = this.preparedData.carMap.getOrError(carPseudoId.value);
        const carType = this.preparedData.carTypeMap.getOrError(car.carType.id);
        const carUsage = new CarUsage(carPseudoId, carIndex, driverAssignments, car, carType, drivers);
        const carIndexId = this.getCarIndexIdOf(carId, carIndex);
        carDriverMap.set(carIndexId, carUsage);
      }
    }

    const orderCarMap: Map<PersistentId, CarUsage[]> = new Map<PersistentId, CarUsage[]>();
    for (const [orderId, carIndexIds] of this.orderCarIndexMap) {
      const carAssignments = carIndexIds.toArray().map((carIndexId) => carDriverMap.get(carIndexId)!);
      orderCarMap.set(orderId.toPseudoId().value, carAssignments);
    }

    return new AssignmentMap(carDriverMap, orderCarMap);
  }

  private getDriverAssignmentOf(carId: string, carIndex: number): IDriverAssignment[] {
    const carIndexMap = this.carDriverMap.get(carId) ?? new Map<number, IDriverAssignment[]>();
    this.carDriverMap.set(carId, carIndexMap);
    const driverAssignments = carIndexMap.get(carIndex) ?? [];
    carIndexMap.set(carIndex, driverAssignments);
    return driverAssignments;
  }

  private getCarIndexesOf(orderId: string): Set<CarIndexId> {
    const carIndexes = this.orderCarIndexMap.get(orderId) ?? new Set<CarIndexId>();
    this.orderCarIndexMap.set(orderId, carIndexes);
    return carIndexes;
  }

  private getCarIndexIdOf(carId: string, carIndex: number): CarIndexId {
    return getCarIndexIdOf(carId.toPseudoId().value, carIndex);
  }
}

interface IAssignmentMap {
  /**
   * route から CarUsage を取る
   *
   * @param carId
   * @param carIndex
   */
  getAssignmentOfRoute(carId: PersistentId, carIndex: number): CarUsage;

  /**
   * order から CarUsage を取る
   *
   * @param orderId
   */
  getAssignmentOfOrder(orderId: PersistentId): CarUsage[];
}

class AssignmentMap implements IAssignmentMap {
  private readonly carDriverMap: Map<CarIndexId, CarUsage>;
  private readonly orderCarMap: Map<PersistentId, CarUsage[]>;

  constructor(carDriverMap: Map<CarIndexId, CarUsage>, orderCarMap: Map<PersistentId, CarUsage[]>) {
    this.carDriverMap = carDriverMap;
    this.orderCarMap = orderCarMap;
  }

  getAssignmentOfRoute(carId: PersistentId, carIndex: number): CarUsage {
    const carIndexId = getCarIndexIdOf(carId, carIndex);
    const assignment = this.carDriverMap.get(carIndexId);
    if (assignment === undefined) throw new Error(`Could not find assignment of carIndexId: ${carIndexId}!`);
    return assignment;
  }

  getAssignmentOfOrder(orderId: PersistentId): CarUsage[] {
    // carId, carIndex は新規作成したルートでは空になりえるのでそういう場合には
    // そのルートに含まれた collection の orderId から CarUsage が引けないという事が起こり得る
    // その場合にはひとまず空配列を返しておく
    const assignment = this.orderCarMap.get(orderId) || [];
    return assignment;
  }
}
