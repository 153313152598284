import { Maybe, PersistentId, Seconds } from '~/framework/typeAliases';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import {
  InfeasibilityCauses,
  InfeasibilityDriverReasons,
  InfeasibilityReasons,
  ScheduleInfeasibilityTypes,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { ClientEntity } from '~/framework/domain/masters/client/clientEntity';
import { GenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { OvertimeWorkType, PreloadStatus } from '~/framework/domain/typeAliases';
import { OrderPlan } from '~/framework/server-api/typeAliases';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { PostponeOrderStatus } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { OrderScrollTarget } from '~/framework/view-models/panels/orderFormPanel';
// このコンポーネントが扱えるインターフェースはこのコンポーネントの領域で定義されているべきと考えるので、
// ここにインターフェースを定義しておく。実際のデータを提供する側でこのインターフェースを実装する。

/**
 * infeasibility に対してありえる解決方法
 */
export enum InfeasibilitySolutionTypes {
  EditOrder,
  EditPreloadStatus,
  EditGenerationSite,
  EditAttendance,
  EditDriver,
  EditDriverAttendance,
  EditDisposalSite,
  RinLink,
}

export interface IPotentialModification {
  drivers: Maybe<IPotentialModificationDriver[]>;
  orders: Maybe<IPotentialModificationOrder[]>;
  disposalSites: Maybe<IPotentialModificationDisposalSite[]>;
}

export interface IPotentialModificationDriver extends DriverEntity {
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
  idealOvertimeWorkType: Maybe<OvertimeWorkType>;
  idealOvertimeWorkableDuration: Maybe<number>;
}

// NOTE: 受注の排出場到着時間に関する修正 suggest
export interface IPotentialModificationOrder extends IOrder {
  orderId: PseudoId;
  collectablePeriodStart: number;
  collectablePeriodEnd: number;
  idealArrivalTime: number;
}

// NOTE: 受注の指定処分場に関する修正 suggest
export interface IPotentialModificationDisposalSite extends DisposalSiteEntity {
  // NOTE: 修正すべき処分場が指定されている受注ID
  orderId: PseudoId;
  order: IOrder;
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
  idealArrivalTime: number;
  attendance: Maybe<IPotentialModificationDisposalSiteAttendance>;
}

// NOTE: 処分場の稼働状況に関する修正 suggest
export interface IPotentialModificationDisposalSiteAttendance {
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
  idealArrivalTime: number;
}

export interface IInfeasibility<D extends IDriverReason> {
  index: number;
  deemedAsSolved: boolean;
  orderId: PseudoId;
  order: IOrder;
  cause: InfeasibilityCauses;
  reasons: Maybe<InfeasibilityReasons[]>;
  driverReasons: Maybe<D[]>;
  assignedDriver: Maybe<DriverEntity>;
  assignedCar: Maybe<AggregatedCarEntity>;
  acceptableCarTypes: Maybe<CarTypeEntity[]>;
  acceptableCarTypeNames: Maybe<string[]>;
  possibleSolutions: IInfeasibilitySolution<any>[];
  hasConditions: boolean;
  potentialModifications: Maybe<IPotentialModification[]>;
  type: ScheduleInfeasibilityTypes;
  assignableCarTypeNames: Maybe<string[]>;
  releaseDriverAssignment: Maybe<boolean>;
  durationAtGenerationSite: Maybe<number>;
  durationAtDisposalSite: Maybe<number>;
  durationOfDriving: Maybe<number>;
  reducibleDurationByHighway: Maybe<number>;

  /**
   * ある解決タイプがあり得るという事になっているかどうかを判定する
   * @param solution
   */
  hasSolution(solution: InfeasibilitySolutionTypes): boolean;
}

/**
 * 受注チェックを行った場合は OrderEntity はまだ存在していない可能性があるので、
 * client と generationSite の情報のみを持ったインターフェースを定義しておき、
 * 受注チェックの場合はこちら側を利用する
 */
// TODO: orderAssignedDisposalSites を追加したい
export interface IOrder {
  client: ClientEntity;
  date: Maybe<Date>;
  plan: Maybe<OrderPlan>;
  collectablePeriodStart: Maybe<Seconds>;
  collectablePeriodEnd: Maybe<Seconds>;
  generationSite: GenerationSiteEntity;
  preloadStatus: PreloadStatus;
  unloadDate: Maybe<Date>;
  postponeOrderStatus: PostponeOrderStatus;
}

export interface IDriverReason {
  driverId: PseudoId;
  driver: DriverEntity;
  reasons: InfeasibilityDriverReasons[];
}

export interface IInfeasibilitySolution<SolutionOption = never> {
  type: InfeasibilitySolutionTypes;
  option?: SolutionOption;
}

export interface IRinLinkSolutionOption {
  path: string;
  label: string;
}

export class EditOrderSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditOrder;
  orderId: PersistentId;
  constructor(id: PersistentId) {
    this.orderId = id;
  }
}

export interface IRinLinkOrderSolutionOption {
  scrollTarget?: OrderScrollTarget;
}
export class EditPreloadStatusSolution implements IInfeasibilitySolution<IRinLinkOrderSolutionOption> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditPreloadStatus;
  orderId: PersistentId;
  option?: IRinLinkOrderSolutionOption;
  constructor(id: PersistentId, scrollTarget?: Maybe<OrderScrollTarget>) {
    this.orderId = id;
    this.option = {
      scrollTarget,
    };
  }
}

export class EditGenerationSiteSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditGenerationSite;
}

export class EditAttendanceSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditAttendance;
}

export class EditDriverAttendanceSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditDriverAttendance;
  driverId: PersistentId;
  constructor(id: PersistentId) {
    this.driverId = id;
  }
}

export class EditDriverSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditDriver;
}

export class EditDisposalSiteSolution implements IInfeasibilitySolution<never> {
  type: InfeasibilitySolutionTypes = InfeasibilitySolutionTypes.EditDisposalSite;
  disposalSiteId: PersistentId;
  constructor(id: PersistentId) {
    this.disposalSiteId = id;
  }
}
export class RinLinkSolution implements IInfeasibilitySolution<IRinLinkSolutionOption> {
  type: InfeasibilitySolutionTypes;
  option: IRinLinkSolutionOption;

  constructor(path: string, label: string) {
    this.type = InfeasibilitySolutionTypes.RinLink;
    this.option = {
      path,
      label,
    };
  }
}
