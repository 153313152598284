import { AggregatedReservationEntity } from '~/framework/domain/reservation/reservation/aggregatedReservationEntity';
import { OrderService, ValidateTaskType } from '~/framework/domain/schedule/order/orderService';
import { IReservationFactory } from '~/framework/factories/reservation/reservationFactory';

import {
  IAcceptReservationData,
  IAcceptReservationMutationResultData,
  reservation$acceptReservationSymbol,
} from '~/framework/server-api/reservation/acceptReservation';
import { reservation$reservationsByDateSymbol } from '~/framework/server-api/reservation/reservationsByDate';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';

import { ICreateData as ICreateOrderData } from '~/framework/server-api/schedule/order/createOrders';
import { IAcceptReservationOrderData } from '~/framework/application/reservation/form';
import {
  CreateGenerationSiteTaskInput,
  CreateIrregularTaskInput,
  CreateOrderAssignableDriversAndNumInput,
  CreateOrderDisposalSitesAndTypeInput,
  CreateOrderRecurringSettingsInput,
  OrderPlanInput,
} from '~/framework/server-api/typeAliases';
import {
  MarginType,
  OrderDisposalSiteAssignmentType,
  OrderStatus,
  PreloadStatus,
  OrderSchedulingPriority,
} from '~/framework/domain/typeAliases';
import { RawScheduleJsonObject } from '~/framework/server-api/schedule/schedule/schedule';
import { order$validateOrdersSymbol } from '~/framework/server-api/schedule/order/validateOrders';

import { RawRouteJsonObject } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { reservation$cancelledReservationsByDateSymbol } from '~/framework/server-api/reservation/cancelledReservationsByDate';
import {
  IPushBackReservationFromCancelledToWaitingReviewData,
  IPushBackReservationFromCancelledToWaitingReviewMutationResultData,
  reservation$pushBackReservationFromCancelledToWaitingReviewSymbol,
} from '~/framework/server-api/reservation/pushBackReservationFromCancelledToWaitingReview';
import {
  ICancelReservationData,
  ICancelReservationMutationResultData,
  reservation$cancelReservationSymbol,
} from '~/framework/server-api/reservation/cancelReservation';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { reservation$waitingReviewReservationDatesByDateRangeSymbol } from '~/framework/server-api/reservation/waitingReviewReservationByDateRange';
import { taskType$getAllSymbol } from '~/framework/server-api/masters/taskType';
import { IOrderRoutingGroup } from '~/framework/server-api/schedule/order/orderRoutingGroup';
import { reservation$reservationDeadlineByDateSymbol } from '~/framework/server-api/reservation/reservationDeadlineByDate';
import { IReservationDeadlineData } from '~/framework/server-api/reservation/reservation';
import { reservation$updateReservationDeadlineSymbol } from '~/framework/server-api/reservation/updateReservationDeadline';
import { ReservationDeadlineStatus } from '~/graphql/graphQLServerApi';

export const reservationSymbol = Symbol('reservation');

// orderApplicationService と同じものを reservationApplicationService でも定義しているのは、今後受注のやつと分岐する可能性がある
// ためである。APIが変わるとか、があるときにこちらだけを差し替えれば良くて Vue SFC には手出さなくてよくなる。
export interface IValidateOrderData {
  date: Maybe<Date>;
  plan: OrderPlanInput;
  orderGroupId: string;
  generationSiteId: string;
  collectablePeriodTemplateName: string | undefined;
  collectablePeriodStart: number | undefined;
  collectablePeriodEnd: number | undefined;
  generationSiteTasks: CreateGenerationSiteTaskInput[];
  irregularTasks: CreateIrregularTaskInput[];
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  preloadStatus: PreloadStatus;
  unloadDate: Date | undefined;
  assignedBaseSiteId: string | undefined;
  // TODO: 処分場の入退場時間の後続リリースで削除
  assignedDisposalSiteIds: string[];
  // TODO: 処分場の入退場時間の後続リリースで削除
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  assignedDisposalSitesAndType: CreateOrderDisposalSitesAndTypeInput;
  assignableDriversAndNum: CreateOrderAssignableDriversAndNumInput;
  assignedCarId: string | undefined;
  assignableCarTypeIds: string[];
  // TODO: carNum に置き換わるので削除する
  minAssignedCarNum: number;
  // TODO: carNum に置き換わるので削除する
  maxAssignedCarNum: number;
  carNum: number;
  note: string | undefined;
  noteForAssignedDriver: string | undefined;
  avoidHighways: boolean;
  fixedArrivalTime: number | undefined;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  checkItemIds: string[];
  routingGroup: IOrderRoutingGroup | undefined;
  fixedDisplayOnReservation: boolean;
  fixedDisplayOnReservationName: string | undefined;
  schedulingPriority: OrderSchedulingPriority;
  recurringSettings: CreateOrderRecurringSettingsInput | undefined;
  status: OrderStatus;
}

export class ReservationApplicationService {
  private reservationFactory: IReservationFactory;
  private serverApis: ServerApiManager;
  private orderService: OrderService;

  constructor(serverApis: ServerApiManager, reservationFactory: IReservationFactory) {
    this.serverApis = serverApis;
    this.reservationFactory = reservationFactory;
    this.orderService = new OrderService();
  }

  async getByDate(date: Date): Promise<AggregatedReservationEntity[]> {
    const reservation$reservationByDateApi = this.serverApis.get(reservation$reservationsByDateSymbol);
    const reservationData = await reservation$reservationByDateApi.reservationsByDate(date);
    return await this.reservationFactory.buildByData(reservationData.map((d) => ({ ...d, isCancelled: false })));
  }

  async getCancelledReservationsByDate(date: Date): Promise<AggregatedReservationEntity[]> {
    const reservation$cancelledReservationsByDate = this.serverApis.get(reservation$cancelledReservationsByDateSymbol);
    const cancelledReservationData = await reservation$cancelledReservationsByDate.cancelledReservationsByDate(date);

    return await this.reservationFactory.buildByData(
      cancelledReservationData.map((d) => ({ ...d, isCancelled: true }))
    );
  }

  async getWaitingReviewReservationDatesByDateRange(start: Date, end: Date): Promise<Date[]> {
    const reservation$waitingReviewReservationDatesByDateRangeApi = this.serverApis.get(
      reservation$waitingReviewReservationDatesByDateRangeSymbol
    );
    const waitingReviewDates =
      await reservation$waitingReviewReservationDatesByDateRangeApi.waitingReviewReservationDatesByDateRange(
        start,
        end
      );
    return waitingReviewDates;
  }

  async getReservationDeadline(date: Date): Promise<IReservationDeadlineData> {
    const reservation$reservationDeadlineByDateApi = this.serverApis.get(reservation$reservationDeadlineByDateSymbol);
    return await reservation$reservationDeadlineByDateApi.reservationDeadlineByDate(date);
  }

  async validateOrder(order: IValidateOrderData): Promise<RawScheduleJsonObject<RawRouteJsonObject>> {
    const validateOrderApi = this.serverApis.get(order$validateOrdersSymbol);
    const validateOrderData: ICreateOrderData = {
      ...order,
      attachmentsToAdd: [],
    };
    const [data] = await validateOrderApi.validateOrders([validateOrderData]);
    return data;
  }

  async acceptReservation(
    reservationId: PersistentId,
    orderData: IAcceptReservationOrderData
  ): Promise<IAcceptReservationMutationResultData> {
    this.orderService.validateTasks(ValidateTaskType.Create, orderData.generationSiteTasks, orderData.irregularTasks);
    this.orderService.validateAssignedDisposalSiteIds(
      orderData,
      await this.serverApis.get(taskType$getAllSymbol).getAll()
    );

    const reservation$acceptReservationApi = this.serverApis.get(reservation$acceptReservationSymbol);

    const acceptReservationData: IAcceptReservationData = {
      id: reservationId,
      order: {
        date: orderData.date,
        plan: orderData.plan,
        orderGroupId: orderData.orderGroupId,
        generationSiteId: orderData.generationSiteId,
        collectablePeriodTemplateName: orderData.collectablePeriodTemplateName,
        collectablePeriodStart: orderData.collectablePeriodStart,
        collectablePeriodEnd: orderData.collectablePeriodEnd,
        generationSiteTasks: orderData.generationSiteTasks,
        irregularTasks: orderData.irregularTasks,
        durationAtGenerationSite: orderData.durationAtGenerationSite,
        routeCollectionAllowed: orderData.routeCollectionAllowed,
        preloadStatus: orderData.preloadStatus,
        unloadDate: orderData.unloadDate,
        assignedBaseSiteId: orderData.assignedBaseSiteId,
        // TODO: 処分場の入退場時間の後続リリースで削除
        assignedDisposalSiteIds: orderData.assignedDisposalSiteIds,
        // TODO: 処分場の入退場時間の後続リリースで削除
        disposalSiteAssignmentType: orderData.disposalSiteAssignmentType,
        assignedDisposalSitesAndType: orderData.assignedDisposalSitesAndType,
        assignableDriversAndNum: {
          minAssignedDriverNum: orderData.minAssignedDriverNum,
          maxAssignedDriverNum: orderData.maxAssignedDriverNum,
          driverAssignmentType: orderData.driverAssignmentType,
          assignableDrivers: orderData.assignableDrivers,
        },
        assignedCarId: orderData.assignedCarId,
        assignableCarTypeIds: orderData.assignableCarTypeIds,
        minAssignedCarNum: orderData.carNum,
        maxAssignedCarNum: orderData.carNum,
        carNum: orderData.carNum,
        note: orderData.note,
        noteForAssignedDriver: orderData.noteForAssignedDriver,
        attachmentsToAdd: [],
        avoidHighways: orderData.avoidHighways,
        fixedArrivalTime: orderData.fixedArrivalTime,
        isFixedArrivalTimeReportNeeded: orderData.isFixedArrivalTimeReportNeeded,
        marginTypeOfFixedArrivalTime: orderData.marginTypeOfFixedArrivalTime,
        marginOfFixedArrivalTime: orderData.marginOfFixedArrivalTime,
        checkItemIds: orderData.checkItemIds,
        routingGroup: orderData.routingGroup,
        fixedDisplayOnReservation: orderData.fixedDisplayOnReservation,
        fixedDisplayOnReservationName: orderData.fixedDisplayOnReservationName,
        schedulingPriority: orderData.schedulingPriority,
        recurringSettings: orderData.recurringSettings,
        status: orderData.status,
      },
    };

    return await reservation$acceptReservationApi.acceptReservation(acceptReservationData);
  }

  async cancelReservation(reservationId: PersistentId): Promise<ICancelReservationMutationResultData> {
    const reservation$cancelReservationApi = this.serverApis.get(reservation$cancelReservationSymbol);

    const cancelReservationData: ICancelReservationData = {
      id: reservationId,
    };

    return await reservation$cancelReservationApi.cancelReservation(cancelReservationData);
  }

  async pushBackReservationFromCancelledToWaitingReview(
    reservationId: PersistentId
  ): Promise<IPushBackReservationFromCancelledToWaitingReviewMutationResultData> {
    const reservation$pushBackReservationFromCancelledToWaitingReviewApi = this.serverApis.get(
      reservation$pushBackReservationFromCancelledToWaitingReviewSymbol
    );

    const pushBackReservationFromCancelledToWaitingReviewData: IPushBackReservationFromCancelledToWaitingReviewData = {
      id: reservationId,
    };

    return await reservation$pushBackReservationFromCancelledToWaitingReviewApi.pushBackReservationFromCancelledToWaitingReview(
      pushBackReservationFromCancelledToWaitingReviewData
    );
  }

  async updateReservationDeadline(date: Date, status: ReservationDeadlineStatus): Promise<IReservationDeadlineData> {
    const reservation$updateReservationDeadlineApi = this.serverApis.get(reservation$updateReservationDeadlineSymbol);

    return await reservation$updateReservationDeadlineApi.updateReservationDeadline(date, status);
  }
}
