import { OrderPlan, OrderRecurringSettings, OrderCheckItem } from '~/framework/server-api/typeAliases';
import {
  DriverAssignmentType,
  DriverType,
  MarginType,
  OrderCreatedVia,
  OrderDisposalSiteAssignmentType,
  OrderSchedulingPriority,
  OrderStatus,
  PreloadStatus,
} from '~/framework/domain/typeAliases';
import { OrderEntity, OrderEntityData } from '~/framework/domain/schedule/order/orderEntity';
import { OrderGroupEntity, OrderGroupEntityData } from '~/framework/domain/masters/order-group/orderGroupEntity';
import {
  GenerationSiteEntity,
  GenerationSiteEntityData,
} from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { DriverEntity, DriverEntityData } from '~/framework/domain/masters/driver/driverEntity';
import { CarTypeEntity, CarTypeEntityData } from '~/framework/domain/masters/car-type/carTypeEntity';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import {
  AggregatedGenerationSiteTaskEntity,
  AggregatedGenerationSiteTaskEntityData,
} from '~/framework/domain/schedule/order/generation-site-task/aggregatedGenerationSiteTaskEntity';
import {
  IrregularTaskEntity,
  IrregularTaskEntityData,
} from '~/framework/domain/schedule/order/irregular-task/irregularTaskEntity';
import { IllegalStateException } from '~/framework/core/exception';
import {
  AggregatedClientEntity,
  AggregatedClientEntityData,
} from '~/framework/domain/masters/client/aggregatedClientEntity';
import {
  AggregatedDisposalSiteEntity,
  AggregatedDisposalSiteEntityData,
} from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { AggregatedCarEntity, AggregatedCarEntityData } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { createLogger } from '~/framework/logger';
import {
  getDriverEntities,
  IOrderAssignableDriver,
} from '~/framework/server-api/schedule/order/driver/assignableDriver';
import { DateCollectablePeriodItem } from '~/components/panels/schedule/r-order-form/dateCollectablePeriodItem';
import { IOrderAssignedDisposalSite } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';
import { UploadedFile } from '~/graphql/graphQLServerApi';
import { IOrderRoutingGroup } from '~/framework/server-api/schedule/order/orderRoutingGroup';

// PostponeOrder(複数候補日を明日以降に送る)が実行されたかどうかを示す
export enum PostponeOrderStatus {
  Default = 'default',
  Success = 'success',
  Failure = 'failure',
}

export interface AggregatedOrderEntityData extends OrderEntityData {
  client: AggregatedClientEntityData;
  orderGroup: OrderGroupEntityData;
  generationSite: GenerationSiteEntityData;
  assignedDisposalSites: AggregatedDisposalSiteEntityData[];
  assignableDriverEntities: Maybe<DriverEntityData[]>;
  assignedCar: Maybe<AggregatedCarEntityData>;
  assignableCarTypes: CarTypeEntityData[];
  generationSiteTasks: AggregatedGenerationSiteTaskEntityData[];
  irregularTasks: IrregularTaskEntityData[];
}

export class AggregatedOrderEntity extends OrderEntity implements AggregatedOrderEntity {
  client: AggregatedClientEntity;
  orderGroup: OrderGroupEntity;

  generationSite: GenerationSiteEntity;
  assignedDisposalSites: AggregatedDisposalSiteEntity[];
  assignableDriverEntities: Maybe<DriverEntity[]>;
  assignableCarTypes: CarTypeEntity[];
  assignedCar: Maybe<AggregatedCarEntity>;

  /**
   * コンテナありのタスク
   */
  generationSiteTasks: AggregatedGenerationSiteTaskEntity[];
  /**
   * その他のタスク
   */
  irregularTasks: IrregularTaskEntity[];
  // 複数候補日を明日以降に送る処理(postponeOrders)の実行結果
  postponeOrderStatus: PostponeOrderStatus;
  // 受注が編集された場合にその受注が配車表Scheduleのどの日付から開かれたかを示す、複数候補日から特定の日付を削除する為に使う
  editScheduleDate: Maybe<Date>;

  /**
   * 現場タスクのチェックと更新を行う
   *
   * @param generationSiteTasks
   * @param irregularTasks
   */
  setGenerationSiteTasks(
    generationSiteTasks: AggregatedGenerationSiteTaskEntity[],
    irregularTasks: IrregularTaskEntity[]
  ): void {
    let types = 0;
    if (0 < generationSiteTasks.length) types++;
    if (0 < irregularTasks.length) types++;
    if (types !== 1) {
      const status = {
        generationSiteTasks: generationSiteTasks.length,
        irregularTasks: irregularTasks.length,
      };
      logger.addBreadcrumb({
        type: 'error',
        message: 'setGenerationSiteTasks',
        data: {
          status,
          generationSiteTasks: JSON.stringify(generationSiteTasks),
          irregularTasks: JSON.stringify(irregularTasks),
        },
      });
      throw new IllegalStateException(
        `Should specify one generation site task type! orderId: ${this.id}, types: ${JSON.stringify(status)}`
      );
    }
    this.generationSiteTasks = generationSiteTasks;
    this.irregularTasks = irregularTasks;
  }

  // 複数候補日を持っているかどうか
  // 単数の場合でも複数の場合でも共通のDateCollectablePeriodItemの配列として返す
  hasCandidateDates(): boolean {
    if (this.plan === undefined) return false;
    if (this.plan.candidateDates === undefined) return false;
    return this.plan.candidateDates.length > 1;
  }

  // 単数、複数を問わない候補日の配列データ
  // plan内にfixedがある場合はfixedのDateを返す、candidateDatesがある場合はその中の最初のDateを返す
  orderPlanCandidateDates(): DateCollectablePeriodItem[] {
    // 単数の場合も複数の場合も DateCollectablePeriodItem の配列として返す
    if (this.plan === undefined) {
      return [
        new DateCollectablePeriodItem(
          this.date,
          this.collectablePeriodTemplateName,
          this.collectablePeriodStart,
          this.collectablePeriodEnd,
          this.unloadDate
        ),
      ];
    }
    // 単数が存在する場合は単数のみ
    if (this.plan.fixed !== undefined) {
      return [
        new DateCollectablePeriodItem(
          this.plan.fixed.date,
          this.plan.fixed.collectablePeriodTemplateName,
          this.plan.fixed.collectablePeriodStart,
          this.plan.fixed.collectablePeriodEnd,
          this.plan.fixed.unloadDate
        ),
      ];
    }
    // 複数が存在する場合は複数のみ
    if (this.plan.candidateDates !== undefined) {
      return this.plan.candidateDates.map((item) => {
        return new DateCollectablePeriodItem(
          item.date,
          item.collectablePeriodTemplateName,
          item.collectablePeriodStart,
          item.collectablePeriodEnd,
          item.unloadDate
        );
      });
    }
    // 仕様上はありえないが単数も複数も存在しない場合はデフォルトを返す
    return [
      new DateCollectablePeriodItem(
        this.date,
        this.collectablePeriodTemplateName,
        this.collectablePeriodStart,
        this.collectablePeriodEnd,
        this.unloadDate
      ),
    ];
  }

  getFirstDate(): Date {
    // dateは現在暗黙の必須項目
    if (this.date === undefined) {
      throw new Error('date is undefined');
    }
    // planの中身がない場合はdateを返す
    if (this.plan === undefined) {
      return this.date;
    }
    // fixedがある場合はfixedのdateを返す
    if (this.plan.fixed !== undefined) {
      return this.plan.fixed.date;
    }
    // candidateDatesがある場合はその中の最初のdateを返す
    if (this.plan.candidateDates !== undefined && 0 < this.plan.candidateDates.length) {
      return this.plan.candidateDates[0].date;
    }
    return this.date;
  }

  getDateType(): 'candidate' | 'candidate-preload' | 'preload' | 'recurring' | 'normal' {
    // パターン
    // - 通常: normal
    // - 複数候補日あり: candidate
    // - 複数候補日あり・宵積みあり: candidate-preload
    // - 宵積みあり: preload
    // - 繰り返し受注: recurring
    if (this.hasCandidateDates() && this.preloadStatus === PreloadStatus.Forced) return 'candidate-preload';
    if (this.hasCandidateDates()) return 'candidate';
    if (this.preloadStatus === PreloadStatus.Forced) return 'preload';
    if (this.recurringSettings !== undefined) return 'recurring';
    return 'normal';
  }

  hasAssignableDrivers(): boolean {
    return this.assignableDriverEntities !== undefined && this.assignableDriverEntities.length > 0;
  }

  /**
   * 乗務員(運転者/補助員の指定が特にないもの)
   */
  driverEntities() {
    if (!this.assignableDriverEntities) return [];
    return getDriverEntities(DriverType.Driver, this.assignableDrivers, this.assignableDriverEntities) || [];
  }

  /**
   * 運転者
   */
  operatorEntities() {
    if (!this.assignableDriverEntities) return [];
    return getDriverEntities(DriverType.Operator, this.assignableDrivers, this.assignableDriverEntities) || [];
  }

  /**
   * 補助員
   */
  helperEntities() {
    if (!this.assignableDriverEntities) return [];
    return getDriverEntities(DriverType.Helper, this.assignableDrivers, this.assignableDriverEntities) || [];
  }

  constructor(
    id: string,
    code: Maybe<string>,
    date: Maybe<Date>,
    plan: Maybe<OrderPlan>,
    serialNumber: number,
    clientId: PersistentId,
    orderGroupId: PersistentId,
    generationSiteId: PersistentId,
    collectablePeriodTemplateName: Maybe<string>,
    collectablePeriodStart: Maybe<number>,
    collectablePeriodEnd: Maybe<number>,
    generationSiteTaskIds: PersistentId[],
    irregularTaskIds: PersistentId[],
    durationAtGenerationSite: number,
    routeCollectionAllowed: boolean,
    preloadStatus: PreloadStatus,
    unloadDate: Maybe<Date>,
    // TODO: 処分場の入退場時間の後続リリースで削除
    assignedDisposalSiteIds: PersistentId[],
    orderAssignedDisposalSites: IOrderAssignedDisposalSite[],
    disposalSiteAssignmentType: OrderDisposalSiteAssignmentType,
    driverAssignmentType: DriverAssignmentType,
    assignableDrivers: IOrderAssignableDriver[],
    assignedCarId: Maybe<PersistentId>,
    assignedBaseSiteId: Maybe<PersistentId>,
    assignableCarTypeIds: PersistentId[],
    carNum: number,
    minAssignedDriverNum: number,
    maxAssignedDriverNum: number,
    note: Maybe<string>,
    noteForAssignedDriver: Maybe<string>,
    noteFromReservation: Maybe<string>,
    attachments: UploadedFile[],
    avoidHighways: boolean,
    fixedArrivalTime: Maybe<number>,
    isFixedArrivalTimeReportNeeded: boolean,
    marginTypeOfFixedArrivalTime: MarginType,
    marginOfFixedArrivalTime: number,
    orderCheckItems: OrderCheckItem[],
    routingGroup: Maybe<IOrderRoutingGroup>,
    fixedDisplayOnReservation: boolean,
    fixedDisplayOnReservationName: Maybe<string>,
    schedulingPriority: OrderSchedulingPriority,
    recurringSettings: Maybe<OrderRecurringSettings>,
    status: OrderStatus,
    createdVia: OrderCreatedVia,
    createdBy: UserEntity,
    createdAt: Date,
    updatedBy: UserEntity,
    updatedAt: Date,
    client: AggregatedClientEntity,
    orderGroup: OrderGroupEntity,
    generationSite: GenerationSiteEntity,
    assignedDisposalSites: AggregatedDisposalSiteEntity[],
    assignableDriverEntities: Maybe<DriverEntity[]>,
    assignedCar: Maybe<AggregatedCarEntity>,
    assignableCarTypes: CarTypeEntity[],
    generationSiteTasks: AggregatedGenerationSiteTaskEntity[],
    irregularTasks: IrregularTaskEntity[],
    postponeOrderStatus: PostponeOrderStatus
  ) {
    super(
      id,
      code,
      date,
      plan,
      serialNumber,
      clientId,
      orderGroupId,
      generationSiteId,
      collectablePeriodTemplateName,
      collectablePeriodStart,
      collectablePeriodEnd,
      generationSiteTaskIds,
      irregularTaskIds,
      durationAtGenerationSite,
      routeCollectionAllowed,
      preloadStatus,
      unloadDate,
      // TODO: 処分場の入退場時間の後続リリースで削除
      assignedDisposalSiteIds,
      orderAssignedDisposalSites,
      disposalSiteAssignmentType,
      driverAssignmentType,
      assignableDrivers,
      assignedCarId,
      assignedBaseSiteId,
      assignableCarTypeIds,
      carNum,
      minAssignedDriverNum,
      maxAssignedDriverNum,
      note,
      noteForAssignedDriver,
      noteFromReservation,
      attachments,
      avoidHighways,
      fixedArrivalTime,
      isFixedArrivalTimeReportNeeded,
      marginTypeOfFixedArrivalTime,
      marginOfFixedArrivalTime,
      orderCheckItems,
      routingGroup,
      fixedDisplayOnReservation,
      fixedDisplayOnReservationName,
      schedulingPriority,
      recurringSettings,
      status,
      createdVia,
      createdBy,
      createdAt,
      updatedBy,
      updatedAt
    );
    this.client = client;
    this.orderGroup = orderGroup;
    this.generationSite = generationSite;
    this.assignedDisposalSites = assignedDisposalSites;
    this.assignableDriverEntities = assignableDriverEntities;
    this.assignedCar = assignedCar;
    this.assignableCarTypes = assignableCarTypes;
    this.generationSiteTasks = generationSiteTasks;
    this.irregularTasks = irregularTasks;
    this.setGenerationSiteTasks(this.generationSiteTasks, this.irregularTasks);
    this.postponeOrderStatus = postponeOrderStatus;
    this.editScheduleDate = undefined;
  }
}

const logger = createLogger(`AggregatedOrderEntity`);
