import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { StoredMapperBase } from '~/framework/core/mapper';
import {
  AggregatedOrderEntity,
  AggregatedOrderEntityData,
  PostponeOrderStatus,
} from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { AggregatedOrderStore } from '~/framework/domain/schedule/order/aggregatedOrderStore';
import { OrderGroupMapper } from '~/framework/domain/masters/order-group/orderGroupMapper';
import { GenerationSiteMapper } from '~/framework/domain/masters/generation-site/generationSiteMapper';
import { AggregatedDisposalSiteMapper } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteMapper';
import { AggregatedClientMapper } from '~/framework/domain/masters/client/aggregatedClientMapper';
import { DriverMapper } from '~/framework/domain/masters/driver/driverMapper';
import { CarTypeMapper } from '~/framework/domain/masters/car-type/carTypeMapper';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { AggregatedClientStore } from '~/framework/domain/masters/client/aggregatedClientStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { AggregatedGenerationSiteTaskMapper } from '~/framework/domain/schedule/order/generation-site-task/aggregatedGenerationSiteTaskMapper';
import { AggregatedWasteTypeStore } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeStore';
import { IrregularTaskMapper } from '~/framework/domain/schedule/order/irregular-task/irregularTaskMapper';
import { AggregatedCarMapper } from '~/framework/domain/masters/car/aggregatedCarMapper';
import { AggregatedCarTypeStore } from '~/framework/domain/masters/car-type/aggregatedCarTypeStore';
import { AggregatedCarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeStore';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { AggregatedDisposalSiteStore } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteStore';

export class AggregatedOrderMapper extends StoredMapperBase<AggregatedOrderEntityData, AggregatedOrderEntity> {
  protected store: AggregatedOrderStore;
  private clientMapper: AggregatedClientMapper;
  private orderGroupMapper: OrderGroupMapper;
  private generationSiteMapper: GenerationSiteMapper;
  private disposalSiteMapper: AggregatedDisposalSiteMapper;
  private driverMapper: DriverMapper;
  private carTypeMapper: CarTypeMapper;
  private carMapper: AggregatedCarMapper;
  private userMapper: UserMapper;
  private generationSiteTaskMapper: AggregatedGenerationSiteTaskMapper;
  private irregularTaskMapper: IrregularTaskMapper;

  constructor(
    store: AggregatedOrderStore,
    clientStore: AggregatedClientStore,
    userStore: UserStore,
    orderGroupStore: OrderGroupStore,
    disposalSiteStore: AggregatedDisposalSiteStore,
    wasteTypeStore: AggregatedWasteTypeStore,
    carStore: AggregatedCarStore,
    carTypeStore: AggregatedCarTypeStore,
    carTypeContainerTypeStore: AggregatedCarTypeContainerTypeStore,
    baseSiteStore: AggregatedBaseSiteStore,
    jwnetWasteMasterStore: JwnetWasteMasterStore
  ) {
    super();
    this.store = store;
    this.clientMapper = new AggregatedClientMapper(clientStore, userStore);
    this.orderGroupMapper = new OrderGroupMapper(orderGroupStore);
    this.generationSiteMapper = new GenerationSiteMapper();
    this.disposalSiteMapper = new AggregatedDisposalSiteMapper(disposalSiteStore, userStore);
    this.driverMapper = new DriverMapper();
    this.carTypeMapper = new CarTypeMapper();
    this.carMapper = new AggregatedCarMapper(
      carStore,
      carTypeStore,
      orderGroupStore,
      carTypeContainerTypeStore,
      baseSiteStore,
      userStore
    );
    this.userMapper = new UserMapper(userStore);
    this.generationSiteTaskMapper = new AggregatedGenerationSiteTaskMapper(
      wasteTypeStore,
      jwnetWasteMasterStore,
      userStore
    );
    this.irregularTaskMapper = new IrregularTaskMapper();
  }

  protected instantiateWithData(data: AggregatedOrderEntityData): AggregatedOrderEntity {
    const client = this.clientMapper.mapSingle(data.client);
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const generationSite = this.generationSiteMapper.mapSingle(data.generationSite);
    const assignedDisposalSites = this.disposalSiteMapper.map(data.assignedDisposalSites);
    const assignedDriverEntities = data.assignableDriverEntities
      ? this.driverMapper.map(data.assignableDriverEntities)
      : undefined;
    const assignableCarTypes = this.carTypeMapper.map(data.assignableCarTypes);
    const assignedCar = data.assignedCar ? this.carMapper.mapSingle(data.assignedCar) : undefined;
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);

    const generationSiteTasks = this.generationSiteTaskMapper.map(data.generationSiteTasks);
    const irregularTasks = this.irregularTaskMapper.map(data.irregularTasks);
    // __typename入っていれば削除する
    delete data.plan?.__typename;
    delete data.plan?.fixed?.__typename;
    data.plan?.candidateDates?.forEach((candidateDate) => delete candidateDate.__typename);

    return new AggregatedOrderEntity(
      data.id,
      data.code,
      data.date,
      data.plan,
      data.serialNumber,
      data.clientId,
      data.orderGroupId,
      data.generationSiteId,
      data.collectablePeriodTemplateName,
      data.collectablePeriodStart,
      data.collectablePeriodEnd,
      data.generationSiteTaskIds,
      data.irregularTaskIds,
      data.durationAtGenerationSite,
      data.routeCollectionAllowed,
      data.preloadStatus,
      data.unloadDate,
      // TODO: 処分場の入退場時間の後続リリースで削除
      data.assignedDisposalSiteIds,
      data.orderAssignedDisposalSites,
      data.disposalSiteAssignmentType,
      data.driverAssignmentType,
      data.assignableDrivers,
      data.assignedCarId,
      data.assignedBaseSiteId,
      data.assignableCarTypeIds,
      data.carNum,
      data.minAssignedDriverNum,
      data.maxAssignedDriverNum,
      data.note,
      data.noteForAssignedDriver,
      data.noteFromReservation,
      data.attachments ?? [],
      data.avoidHighways,
      data.fixedArrivalTime,
      data.isFixedArrivalTimeReportNeeded,
      data.marginTypeOfFixedArrivalTime,
      data.marginOfFixedArrivalTime,
      data.orderCheckItems,
      data.routingGroup,
      data.fixedDisplayOnReservation,
      data.fixedDisplayOnReservationName,
      data.schedulingPriority,
      data.recurringSettings,
      data.status,
      data.createdVia,
      createdBy,
      data.createdAt,
      updatedBy,
      data.updatedAt,
      client,
      orderGroup,
      generationSite,
      assignedDisposalSites,
      assignedDriverEntities,
      assignedCar,
      assignableCarTypes,
      generationSiteTasks,
      irregularTasks,
      PostponeOrderStatus.Default
    );
  }

  protected updateWithData(data: AggregatedOrderEntityData, entity: AggregatedOrderEntity): void {
    const client = this.clientMapper.mapSingle(data.client);
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const generationSite = this.generationSiteMapper.mapSingle(data.generationSite);
    const assignedDisposalSites = this.disposalSiteMapper.map(data.assignedDisposalSites);
    const assignedDriverEntities = data.assignableDriverEntities
      ? this.driverMapper.map(data.assignableDriverEntities)
      : undefined;
    const assignedCar = data.assignedCar ? this.carMapper.mapSingle(data.assignedCar) : undefined;
    const assignableCarTypes = this.carTypeMapper.map(data.assignableCarTypes);
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    const generationSiteTasks = this.generationSiteTaskMapper.map(data.generationSiteTasks);
    const irregularTasks = this.irregularTaskMapper.map(data.irregularTasks);
    // __typename入っていれば削除する
    delete data.plan?.__typename;
    delete data.plan?.fixed?.__typename;
    data.plan?.candidateDates?.forEach((candidateDate) => delete candidateDate.__typename);

    entity.date = data.date;
    entity.plan = data.plan;
    entity.clientId = data.clientId;
    entity.orderGroupId = data.orderGroupId;
    entity.generationSiteId = data.generationSiteId;
    entity.collectablePeriodTemplateName = data.collectablePeriodTemplateName;
    entity.collectablePeriodStart = data.collectablePeriodStart;
    entity.collectablePeriodEnd = data.collectablePeriodEnd;
    entity.generationSiteTaskIds = data.generationSiteTaskIds;
    entity.irregularTaskIds = data.irregularTaskIds;
    entity.durationAtGenerationSite = data.durationAtGenerationSite;
    entity.routeCollectionAllowed = data.routeCollectionAllowed;
    entity.preloadStatus = data.preloadStatus;
    entity.unloadDate = data.unloadDate;
    // TODO: 処分場の入退場時間の後続リリースで削除
    entity.assignedDisposalSiteIds = data.assignedDisposalSiteIds;
    entity.disposalSiteAssignmentType = data.disposalSiteAssignmentType;
    entity.orderAssignedDisposalSites = data.orderAssignedDisposalSites;
    entity.driverAssignmentType = data.driverAssignmentType;
    entity.assignableDrivers = data.assignableDrivers;
    entity.assignedCarId = data.assignedCarId;
    entity.assignableCarTypeIds = data.assignableCarTypes.map((item) => item.id);
    entity.assignedBaseSiteId = data.assignedBaseSiteId;
    entity.carNum = data.carNum;
    entity.minAssignedDriverNum = data.minAssignedDriverNum;
    entity.maxAssignedDriverNum = data.maxAssignedDriverNum;
    entity.note = data.note;
    entity.noteForAssignedDriver = data.noteForAssignedDriver;
    entity.noteFromReservation = data.noteFromReservation;
    entity.attachments = data.attachments;
    entity.avoidHighways = data.avoidHighways;
    entity.fixedArrivalTime = data.fixedArrivalTime;
    entity.isFixedArrivalTimeReportNeeded = data.isFixedArrivalTimeReportNeeded;
    entity.marginTypeOfFixedArrivalTime = data.marginTypeOfFixedArrivalTime;
    entity.marginOfFixedArrivalTime = data.marginOfFixedArrivalTime;
    entity.orderCheckItems = data.orderCheckItems;
    entity.routingGroup = data.routingGroup;
    entity.fixedDisplayOnReservation = data.fixedDisplayOnReservation;
    entity.fixedDisplayOnReservationName = data.fixedDisplayOnReservationName;
    entity.schedulingPriority = data.schedulingPriority;
    entity.recurringSettings = data.recurringSettings;
    entity.status = data.status;
    entity.client = client;
    entity.orderGroup = orderGroup;
    entity.generationSite = generationSite;
    entity.assignedDisposalSites = assignedDisposalSites;
    entity.assignableDriverEntities = assignedDriverEntities;
    entity.assignedCar = assignedCar;
    entity.assignableCarTypes = assignableCarTypes;
    entity.createdBy = createdBy;
    entity.createdAt = data.createdAt;
    entity.updatedBy = updatedBy;
    entity.updatedAt = data.updatedAt;
    entity.setGenerationSiteTasks(generationSiteTasks, irregularTasks);
  }
}
