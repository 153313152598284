import { Maybe } from '~/framework/typeAliases';
import { Attendance, DisposalSiteAttendanceStatus } from '~/graphql/graphQLServerApi';

// NOTE: graphql query で disposalSite は id, name に絞っているため、独自に型定義をしている
type DisposalSite = {
  id: string;
  name: string;
};

export interface DisposalSiteAttendanceData {
  id: string;
  disposalSite: DisposalSite;
  attendance: Attendance;
  periodStart: Maybe<number>;
  periodEnd: Maybe<number>;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  status: DisposalSiteAttendanceStatus;
  acceptanceLimit: number;
  note: Maybe<string>;
}

export class DisposalSiteAttendanceEntity {
  readonly id: string;
  readonly persistentId: string;
  disposalSite: DisposalSite;
  attendance: Attendance;
  periodStart: Maybe<number>;
  periodEnd: Maybe<number>;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  status: DisposalSiteAttendanceStatus;
  acceptanceLimit: number;
  note: Maybe<string>;

  constructor(
    id: string,
    disposalSite: DisposalSite,
    attendance: Attendance,
    periodStart: Maybe<number>,
    periodEnd: Maybe<number>,
    restPeriodStart: Maybe<number>,
    restPeriodEnd: Maybe<number>,
    status: DisposalSiteAttendanceStatus,
    acceptanceLimit: number,
    note: Maybe<string>
  ) {
    this.id = id;
    this.persistentId = id;
    this.disposalSite = disposalSite;
    this.attendance = attendance;
    this.periodStart = periodStart;
    this.periodEnd = periodEnd;
    this.restPeriodStart = restPeriodStart;
    this.restPeriodEnd = restPeriodEnd;
    this.status = status;
    this.acceptanceLimit = acceptanceLimit;
    this.note = note;
  }
}
