import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { client$getByIdsSymbol } from '~/framework/server-api/masters/client';
import { AggregatedClientMapper } from '~/framework/domain/masters/client/aggregatedClientMapper';
import { AggregatedOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import {
  ICreateData as ICreateOrderData,
  order$createOrdersSymbol,
} from '~/framework/server-api/schedule/order/createOrders';
import {
  IUpdateData as IUpdateOrderData,
  order$updateOrdersSymbol,
} from '~/framework/server-api/schedule/order/updateOrders';
import { order$ordersByIdsSymbol } from '~/framework/server-api/schedule/order/ordersByIds';
import { order$ordersByDateSymbol } from '~/framework/server-api/schedule/order/ordersByDate';
import { OrderService, ValidateTaskType } from '~/framework/domain/schedule/order/orderService';
import { order$cancelOrderSymbol } from '~/framework/server-api/schedule/order/cancelOrder';
import { order$activateOrderSymbol } from '~/framework/server-api/schedule/order/activateOrder';
import {
  order$postponeOrdersSymbol,
  IPostponeData,
  PostponeOrderResultTypes,
} from '~/framework/server-api/schedule/order/postponeOrders';
import {
  CreateGenerationSiteTaskInput,
  CreateIrregularTaskInput,
  CreateOrderAssignableDriversAndNumInput,
  CreateOrderDisposalSitesAndTypeInput,
  CreateOrderRecurringSettingsInput,
  OrderRoutingGroupInput,
  OrderPlanInput,
} from '~/framework/server-api/typeAliases';
import {
  MarginType,
  OrderDisposalSiteAssignmentType,
  OrderStatus,
  PreloadStatus,
  OrderSchedulingPriority,
} from '~/framework/domain/typeAliases';
import { IOrderFactory } from '~/framework/factories/schedule/orderFactory';
import { RawScheduleJsonObject } from '~/framework/server-api/schedule/schedule/schedule';
import { order$validateOrdersSymbol } from '~/framework/server-api/schedule/order/validateOrders';
import { taskType$getAllSymbol } from '~/framework/server-api/masters/taskType';
import { IllegalArgumentException } from '~/framework/core/exception';

import { retry } from '~/framework/core/retry/retry';
import { GraphQLResultConsistencyException } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { ExponentialBackoffStrategy } from '~/framework/core/retry/exponentialBackoffStrategy';
import { AggregatedClientEntity } from '~/framework/domain/masters/client/aggregatedClientEntity';
import { RawRouteJsonObject } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { IOrderDefault } from '~/framework/server-api/schedule/order/order-default/orderDefault';
import { orderDefault$orderDefaultByGenerationSiteId } from '~/framework/server-api/schedule/order/order-default/orderDefaultByGenerationSiteId';
import { ILatestOrdersCondition, order$latestOrders } from '~/framework/server-api/schedule/order/latestOrders';
import { IRoutableOrdersCondition, order$routableOrders } from '~/framework/server-api/schedule/order/routableOrders';
import { convertOrderPlanToOrderPlanInput } from '~/framework/domain/schedule/order/orderUtils';
import { IGenerationSiteTaskItem } from '~/components/panels/schedule/r-order-form/r-generation-site-task-field/generationSiteTaskItem';
import { AggregatedGenerationSiteTaskEntity } from '~/framework/domain/schedule/order/generation-site-task/aggregatedGenerationSiteTaskEntity';
import { order$updateOrderCheckItemSymbol } from '~/framework/server-api/schedule/order/updateOrderCheckItem';
export const orderSymbol = Symbol('order');
export interface IValidateOrderData {
  date: Maybe<Date>;
  plan: OrderPlanInput;
  orderGroupId: string;
  generationSiteId: string;
  collectablePeriodTemplateName: string | undefined;
  collectablePeriodStart: number | undefined;
  collectablePeriodEnd: number | undefined;
  generationSiteTasks: CreateGenerationSiteTaskInput[];
  irregularTasks: CreateIrregularTaskInput[];
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  preloadStatus: PreloadStatus;
  unloadDate: Date | undefined;
  assignedBaseSiteId: string | undefined;
  // TODO: 処分場の入退場時間の後続リリースで削除
  assignedDisposalSiteIds: string[];
  // TODO: 処分場の入退場時間の後続リリースで削除
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  assignedDisposalSitesAndType: CreateOrderDisposalSitesAndTypeInput;
  assignableDriversAndNum: CreateOrderAssignableDriversAndNumInput;
  assignedCarId: string | undefined;
  assignableCarTypeIds: string[];
  // TODO: carNum に置き換わるので削除する
  minAssignedCarNum: number;
  // TODO: carNum に置き換わるので削除する
  maxAssignedCarNum: number;
  carNum: number;
  note: string | undefined;
  noteForAssignedDriver: string | undefined;
  avoidHighways: boolean;
  fixedArrivalTime: number | undefined;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  checkItemIds: string[];
  routingGroup: OrderRoutingGroupInput | undefined;
  fixedDisplayOnReservation: boolean;
  fixedDisplayOnReservationName: string | undefined;
  schedulingPriority: OrderSchedulingPriority;
  recurringSettings: CreateOrderRecurringSettingsInput | undefined;
  status: OrderStatus;
}

export interface IOrderApplicationService {
  [orderSymbol]: void;

  create(data: ICreateOrderData[]): Promise<AggregatedOrderEntity[]>;

  update(date: IUpdateOrderData): Promise<AggregatedOrderEntity>;

  cancel(id: PersistentId): Promise<AggregatedOrderEntity>;

  activate(id: PersistentId): Promise<AggregatedOrderEntity>;

  /**
   * @param id
   * @param includeFollowingRecurringOrders 繰り返される受注の場合、値を与えてはならない（undefined のみ許容）
   */
  delete(id: PersistentId, includeFollowingRecurringOrders: Maybe<boolean>): Promise<AggregatedOrderEntity>;
  getByDate(date: Date): Promise<AggregatedOrderEntity[]>;
  getById(id: PersistentId): Promise<AggregatedOrderEntity>;
  getByIds(ids: PersistentId[]): Promise<AggregatedOrderEntity[]>;
  getLatestOrders(condition: ILatestOrdersCondition): Promise<AggregatedOrderEntity[]>;
  getRoutableOrders(condition: IRoutableOrdersCondition): Promise<AggregatedOrderEntity[]>;
  validateOrder(order: IValidateOrderData): Promise<RawScheduleJsonObject<RawRouteJsonObject>>;
  getOrderDefaultByGenerationSiteId(id: PersistentId): Promise<IOrderDefault>;
  postponeOrders(data: IPostponeData[]): Promise<PostponeOrderResultTypes[]>;

  /**
   * 受注毎に、作業が「作業種別」、「品目」と「荷姿」の組み合わせでユニークである。
   * 作業のユニークなキーを返す関数。
   * @param generationSiteTask
   * @returns
   */
  getGenerationSiteTaskKey(generationSiteTask: IGenerationSiteTaskItem | AggregatedGenerationSiteTaskEntity): string;
}

export class OrderApplicationService implements IOrderApplicationService {
  [orderSymbol] = undefined;
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  private readonly orderFactory: IOrderFactory;

  constructor(store: Store, serverApis: ServerApiManager, orderFactory: IOrderFactory) {
    this.store = store;
    this.serverApis = serverApis;
    this.orderFactory = orderFactory;
  }

  async create(data: ICreateOrderData[]): Promise<AggregatedOrderEntity[]> {
    const service = new OrderService();
    for (const singleData of data) {
      service.validateTasks(ValidateTaskType.Create, singleData.generationSiteTasks, singleData.irregularTasks);
      service.validateAssignedDisposalSiteIds(singleData, await this.serverApis.get(taskType$getAllSymbol).getAll());
    }

    const order$createOrdersApi = this.serverApis.get(order$createOrdersSymbol);
    const order$ordersByIdsApi = this.serverApis.get(order$ordersByIdsSymbol);
    // 取り直すのは無駄なのだが、こうしてしまった方が楽なので
    // 将来的に create の結果を Order の型にできればこの必要はない
    const createResult = await order$createOrdersApi.createOrders(data);
    const ids = createResult.map((item) => item.id);
    const entities = await this.getOrdersWithRetry(async () => {
      const data = await order$ordersByIdsApi.ordersByIds(ids);
      return await this.orderFactory.buildByData(data);
    });
    return entities;
  }

  async update(data: IUpdateOrderData): Promise<AggregatedOrderEntity> {
    const service = new OrderService();
    service.validateTasks(ValidateTaskType.Update, data.generationSiteTasks, data.irregularTasks);
    service.validateAssignedDisposalSiteIds(data, await this.serverApis.get(taskType$getAllSymbol).getAll());
    const order$updateOrdersApi = this.serverApis.get(order$updateOrdersSymbol);
    const order$ordersByIdsApi = this.serverApis.get(order$ordersByIdsSymbol);

    if (data.plan === undefined) {
      throw new Error('date.plan is undefined');
    }
    const [createResult] = await order$updateOrdersApi.updateOrders([data]);
    const id = createResult.id;
    const [entity] = await this.getOrdersWithRetry(async () => {
      const data = await order$ordersByIdsApi.ordersByIds([id]);
      return await this.orderFactory.buildByData(data);
    });
    return entity;
  }

  async cancel(id: PersistentId): Promise<AggregatedOrderEntity> {
    const order$cancelOrderApi = this.serverApis.get(order$cancelOrderSymbol);
    const data = await order$cancelOrderApi.cancelOrder(id);
    const [entity] = await this.orderFactory.buildByData([data]);
    return entity;
  }

  async activate(id: PersistentId): Promise<AggregatedOrderEntity> {
    const order$activateOrderApi = this.serverApis.get(order$activateOrderSymbol);
    const data = await order$activateOrderApi.activateOrder(id);
    const [entity] = await this.orderFactory.buildByData([data]);
    return entity;
  }

  async delete(id: PersistentId, includeFollowingRecurringOrders: Maybe<boolean>): Promise<AggregatedOrderEntity> {
    const order$ordersByIdsApi = this.serverApis.get(order$ordersByIdsSymbol);
    const order$updateOrdersApi = this.serverApis.get(order$updateOrdersSymbol);

    const [data] = await order$ordersByIdsApi.ordersByIds([id]);
    if (!data.recurringSettings && includeFollowingRecurringOrders !== undefined) {
      throw new IllegalArgumentException(`includeFollowingRecurringOrders parameter cannot be given to one-shot order`);
    }

    // status を deleted に書き換える事で削除されたという事にしている
    data.status = OrderStatus.Deleted;

    // __typename入っていれば削除する
    delete data.plan?.__typename;
    delete data.plan?.fixed?.__typename;
    data.plan?.candidateDates?.forEach((candidateDate) => delete candidateDate.__typename);
    if (data.date === undefined) {
      throw new Error('date is undefined');
    }
    delete data.routingGroup?.__typename;

    const updateData: IUpdateOrderData = {
      id: data.id,
      date: data.date,
      plan: convertOrderPlanToOrderPlanInput(
        data.plan,
        data.date,
        data.collectablePeriodTemplateName,
        data.collectablePeriodStart,
        data.collectablePeriodEnd,
        data.unloadDate
      ),
      orderGroupId: data.orderGroupId,
      generationSiteId: data.generationSiteId,
      collectablePeriodTemplateName: data.collectablePeriodTemplateName,
      collectablePeriodStart: data.collectablePeriodStart,
      collectablePeriodEnd: data.collectablePeriodEnd,
      generationSiteTasks: undefined, // undefined = 更新しない
      irregularTasks: undefined, // undefined = 更新しない
      durationAtGenerationSite: data.durationAtGenerationSite,
      routeCollectionAllowed: data.routeCollectionAllowed,
      preloadStatus: data.preloadStatus,
      unloadDate: data.unloadDate,
      assignedBaseSiteId: data.assignedBaseSiteId,
      // TODO: 処分場の入退場時間の後続リリースで削除
      assignedDisposalSiteIds: data.assignedDisposalSiteIds,
      // TODO: 処分場の入退場時間の後続リリースで削除
      disposalSiteAssignmentType: data.disposalSiteAssignmentType,
      assignedDisposalSitesAndType: {
        orderDisposalSites: data.orderAssignedDisposalSites.map((assignedDisposalSite) => {
          return {
            disposalSiteId: assignedDisposalSite.disposalSite.id,
            // NOTE: 本来はFEがデフォルトの値を計算しているが、削除は api から orderData を直接取得してそのまま update をかけるため
            // 特に意味はないが undefined の場合は 0 を設定している
            durationAtEntrance: assignedDisposalSite.durationAtEntrance ? assignedDisposalSite.durationAtEntrance : 0,
            priority: assignedDisposalSite.priority,
            disposablePeriodStart: assignedDisposalSite.disposablePeriodStart,
            disposablePeriodEnd: assignedDisposalSite.disposablePeriodEnd,
          };
        }),
        disposalSiteAssignmentType: data.disposalSiteAssignmentType,
      },
      assignableDriversAndNum: {
        minAssignedDriverNum: data.minAssignedDriverNum,
        maxAssignedDriverNum: data.maxAssignedDriverNum,
        driverAssignmentType: data.driverAssignmentType,
        // NOTE: remove __typeName
        assignableDrivers: data.assignableDrivers.map((assignableDriver) => {
          return {
            driverType: assignableDriver.driverType,
            driverId: assignableDriver.driverId,
          };
        }),
      },
      assignedCarId: data.assignedCarId,
      assignableCarTypeIds: data.assignableCarTypeIds,
      minAssignedCarNum: data.carNum,
      maxAssignedCarNum: data.carNum,
      carNum: data.carNum,
      note: data.note,
      noteForAssignedDriver: data.noteForAssignedDriver,
      attachmentsToAdd: [],
      attachmentsToRemove: [],
      avoidHighways: data.avoidHighways,
      fixedArrivalTime: data.fixedArrivalTime,
      isFixedArrivalTimeReportNeeded: data.isFixedArrivalTimeReportNeeded,
      marginTypeOfFixedArrivalTime: data.marginTypeOfFixedArrivalTime,
      marginOfFixedArrivalTime: data.marginOfFixedArrivalTime,
      checkItemIds: data.orderCheckItems.map((item) => item.checkItem.id),
      routingGroup: data.routingGroup,
      fixedDisplayOnReservation: data.fixedDisplayOnReservation,
      fixedDisplayOnReservationName: data.fixedDisplayOnReservationName,
      schedulingPriority: data.schedulingPriority,
      includeFollowingRecurringOrders,
      status: data.status,
    };
    await order$updateOrdersApi.updateOrders([updateData]);
    const [updatedEntity] = await this.orderFactory.buildByData([data]);
    return updatedEntity;
  }

  async getClientById(id: PersistentId): Promise<AggregatedClientEntity> {
    const clientMapper: AggregatedClientMapper = new AggregatedClientMapper(
      this.store.masters.aggregatedClient,
      this.store.masters.user
    );

    const client$getByIdsApi = this.serverApis.get(client$getByIdsSymbol);
    const [client] = await client$getByIdsApi.getByIds([id]);

    return clientMapper.mapSingle(client);
  }

  async getByDate(date: Date): Promise<AggregatedOrderEntity[]> {
    const order$ordersByDateApi = this.serverApis.get(order$ordersByDateSymbol);
    const entities = await this.getOrdersWithRetry(async () => {
      const data = await order$ordersByDateApi.ordersByDate(date);
      return await this.orderFactory.buildByData(data);
    });

    return entities;
  }

  async getById(id: PersistentId): Promise<AggregatedOrderEntity> {
    const [entity] = await this.getByIds([id]);
    return entity;
  }

  async getByIds(ids: PersistentId[]): Promise<AggregatedOrderEntity[]> {
    const order$ordersByIdsApi = this.serverApis.get(order$ordersByIdsSymbol);
    const entities = await this.getOrdersWithRetry(async () => {
      const data = await order$ordersByIdsApi.ordersByIds(ids);
      return await this.orderFactory.buildByData(data);
    });
    return entities;
  }

  async getLatestOrders(condition: ILatestOrdersCondition): Promise<AggregatedOrderEntity[]> {
    const order$latestOrdersApi = this.serverApis.get(order$latestOrders);

    const entities = await this.getOrdersWithRetry(async () => {
      const data = await order$latestOrdersApi.latestOrders(condition);
      return await this.orderFactory.buildByData(data);
    });
    return entities;
  }

  async getRoutableOrders(condition: IRoutableOrdersCondition): Promise<AggregatedOrderEntity[]> {
    const order$routableOrdersApi = this.serverApis.get(order$routableOrders);

    const entities = await this.getOrdersWithRetry(async () => {
      const data = await order$routableOrdersApi.routableOrders(condition);
      return await this.orderFactory.buildByData(data);
    });

    return entities;
  }

  async postponeOrders(data: IPostponeData[]): Promise<PostponeOrderResultTypes[]> {
    const order$ordersByIdsApi = this.serverApis.get(order$ordersByIdsSymbol);
    const order$postponeOrdersApi = this.serverApis.get(order$postponeOrdersSymbol);

    const results = await order$postponeOrdersApi.postponeOrders(data);
    // postponeOrdersでエラーが出ていないOrderをUpdateする
    const ids: PersistentId[] = [];
    results.forEach((result) => {
      if (result.__typename === 'Order') {
        ids.push(result.id);
      }
    });
    await this.getOrdersWithRetry(async () => {
      const data = await order$ordersByIdsApi.ordersByIds(ids);
      return await this.orderFactory.buildByData(data);
    });
    return results;
  }

  async updateOrderCheckItem(orderCheckItemId: string, checked: boolean): Promise<AggregatedOrderEntity> {
    const order$updateOrderCheckItemApi = this.serverApis.get(order$updateOrderCheckItemSymbol);

    const result = await order$updateOrderCheckItemApi.updateOrderCheckItem(orderCheckItemId, checked);
    const [entity] = await this.orderFactory.buildByData([result]);

    return entity;
  }

  async validateOrder(order: IValidateOrderData): Promise<RawScheduleJsonObject<RawRouteJsonObject>> {
    const validateOrderApi = this.serverApis.get(order$validateOrdersSymbol);
    const validateOrderData: ICreateOrderData = {
      ...order,
      attachmentsToAdd: [],
    };
    const [data] = await validateOrderApi.validateOrders([validateOrderData]);
    return data;
  }

  async getOrderDefaultByGenerationSiteId(id: PersistentId): Promise<IOrderDefault> {
    const order$orderDefaultByGenerationSiteIdApi = this.serverApis.get(orderDefault$orderDefaultByGenerationSiteId);
    const result = await order$orderDefaultByGenerationSiteIdApi.orderDefaultByGenerationSiteId(id);
    return result;
  }

  getGenerationSiteTaskKey(
    generationSiteTaskItem: IGenerationSiteTaskItem | AggregatedGenerationSiteTaskEntity
  ): string {
    return `${generationSiteTaskItem.taskType?.id}-${generationSiteTaskItem.wasteTypeId}-${generationSiteTaskItem.containerTypeId}`;
  }

  /**
   * 受注情報を取得する様な API 操作を func として渡し、現場タスクなどの取得エラーが起きた場合には
   * リトライを行って再取得を試みる。受注情報を取得した際に GenerationSiteTasksByIds の様な API を
   * 多段階で呼ぶ可能性があるが、API の呼び出し間隔によっては最初に受注情報を取得した際に得られた
   * generationSiteTaskIds の中身が実は他の誰かによって更新されたために削除されたりして
   * GenerationSiteTasksByIds で取得できない事がある。しかしサーバー側で受注情報自体が壊れている訳ではなく
   * 再取得すれば取得できるので、ここで retry している。
   *
   * 1.(受注情報の取得) -> 2.(誰かが Order を更新して generationSiteTaskIds の指すタスクがなくなる) ->
   *   3.(GenerationSiteTasksByIds がコケる) -> 4.(自動で 1 からリトライ)
   *
   * 根本的には Order の中の generationTaskIds をネストして Type 自体を返せば解決する。
   *
   * @param func 必要に応じて複数回呼ばれる可能性がある事に注意する事
   * @private
   */
  private async getOrdersWithRetry<T>(func: () => Promise<T>): Promise<T> {
    return await retry(func, this.isGenerationSiteTaskNotFoundException, new ExponentialBackoffStrategy(500, 3));
  }

  private isGenerationSiteTaskNotFoundException(error: Error): boolean {
    return (
      error instanceof GraphQLResultConsistencyException &&
      (error.typename === 'GenerationSiteTasksByIds' || error.typename === 'IrregularTasksByIds')
    );
  }
}
