import { IResultData } from '~/framework/server-api/schedule/order/order';
import {
  CreateGenerationSiteTaskInput,
  CreateIrregularTaskInput,
  CreateOrderAssignableDriversAndNumInput,
  CreateOrderDisposalSitesAndTypeInput,
  CreateOrderRecurringSettingsInput,
  OrderRoutingGroupInput,
  OrderPlanInput,
} from '~/framework/server-api/typeAliases';
import {
  OrderSchedulingPriority,
  OrderStatus,
  PreloadStatus,
  OrderDisposalSiteAssignmentType,
  MarginType,
} from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';

export const order$createOrdersSymbol = Symbol('order$createOrdersSymbol');

export interface ICreateOrdersApi {
  [order$createOrdersSymbol]: void;
  createOrders(data: ICreateData[]): Promise<IResultData[]>;
}

export interface ICreateData {
  date: Maybe<Date>;
  plan: OrderPlanInput;
  orderGroupId: string;
  generationSiteId: string;
  collectablePeriodTemplateName: string | undefined;
  collectablePeriodStart: number | undefined;
  collectablePeriodEnd: number | undefined;
  generationSiteTasks: CreateGenerationSiteTaskInput[];
  irregularTasks: CreateIrregularTaskInput[];
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  preloadStatus: PreloadStatus;
  unloadDate: Date | undefined;
  assignedBaseSiteId: string | undefined;
  assignedDisposalSiteIds: string[];
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  assignedDisposalSitesAndType: CreateOrderDisposalSitesAndTypeInput;
  assignableDriversAndNum: CreateOrderAssignableDriversAndNumInput;
  assignedCarId: string | undefined;
  assignableCarTypeIds: string[];
  // TODO: carNum に置き換わるので削除する
  minAssignedCarNum: number;
  // TODO: carNum に置き換わるので削除する
  maxAssignedCarNum: number;
  carNum: number;
  note: string | undefined;
  noteForAssignedDriver: string | undefined;
  attachmentsToAdd: File[];
  avoidHighways: boolean;
  fixedArrivalTime: number | undefined;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  checkItemIds: string[];
  routingGroup: OrderRoutingGroupInput | undefined;
  fixedDisplayOnReservation: boolean;
  fixedDisplayOnReservationName: string | undefined;
  schedulingPriority: OrderSchedulingPriority;
  recurringSettings: CreateOrderRecurringSettingsInput | undefined;
  status: OrderStatus;
}
