import { SoftDeleteStatus } from '~/framework/domain/typeAliases';

export class CheckItemEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  default: boolean;
  status: SoftDeleteStatus;

  // NOTE: constructor では default は予約語で使えないので defaultValue としている
  constructor(id: string, name: string, defaultValue: boolean, status: SoftDeleteStatus) {
    this.id = id;
    this.persistentId = id;
    this.name = name;
    this.default = defaultValue;
    this.status = status;
  }
}
