import { IMapper, MapperBase } from '~/framework/core/mapper';
import {
  DisposalSiteAttendanceData,
  DisposalSiteAttendanceEntity,
} from '~/framework/domain/masters/disposal-site-attendance/disposalSiteAttendanceEntity';
import { AttendanceEntity } from '../attendance/attendanceEntity';

export interface IDisposalSiteAttendanceMapper
  extends IMapper<DisposalSiteAttendanceData, DisposalSiteAttendanceEntity> {
  //
}

export class DisposalSiteAttendanceMapper
  extends MapperBase<DisposalSiteAttendanceData, DisposalSiteAttendanceEntity>
  implements IDisposalSiteAttendanceMapper
{
  constructor() {
    super();
  }

  protected instantiateWithData(data: DisposalSiteAttendanceData): DisposalSiteAttendanceEntity {
    return new DisposalSiteAttendanceEntity(
      data.id,
      data.disposalSite,
      data.attendance,
      data.periodStart,
      data.periodEnd,
      data.restPeriodStart,
      data.restPeriodEnd,
      data.status,
      data.acceptanceLimit,
      data.note
    );
  }

  protected updateWithData(data: DisposalSiteAttendanceData, entity: DisposalSiteAttendanceEntity): void {
    entity.disposalSite = data.disposalSite;
    entity.attendance = new AttendanceEntity(data.attendance.id, data.attendance.date);
    entity.periodStart = data.periodStart;
    entity.periodEnd = data.periodEnd;
    entity.restPeriodStart = data.restPeriodStart;
    entity.restPeriodEnd = data.restPeriodEnd;
    entity.status = data.status;
    entity.acceptanceLimit = data.acceptanceLimit;
    entity.note = data.note;
  }
}
