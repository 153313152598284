
import Vue, { PropType } from 'vue';
import RDisposalSiteDisposablePeriodMultipleDialog from '~/components/panels/schedule/r-order-form/RDisposalSiteDisposablePeriodMultipleDialog.vue';
import { OrderDisposalSiteAssignmentType } from '~/framework/domain/typeAliases';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { IOrderAssignedDisposalSite } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';
import { formatPeriod } from '~/framework/services/date-time/date-time';
import { createLogger } from '~/framework/logger';

type DataType = {
  isDisposablePeriodMultipleDialogActive: boolean;
};

export type DisposablePeriodItem = IOrderAssignedDisposalSite & {
  name: string;
};

export type DisposalSiteDisposablePeriod = {
  disposalSiteId: string;
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
};

const rinLogger = createLogger('RDisposalSitesDisposablePeriod');

export default Vue.extend({
  name: 'RDisposalSitesDisposablePeriod',
  components: {
    RDisposalSiteDisposablePeriodMultipleDialog,
  },
  props: {
    orderAssignedDisposalSites: {
      type: Array as PropType<IOrderAssignedDisposalSite[]>,
      required: true,
    },
    disposalSiteAssignmentType: {
      type: String as PropType<OrderDisposalSiteAssignmentType>,
      required: true,
    },
    disposalSiteEntities: {
      type: Array as PropType<DisposalSiteEntity[]>,
      required: true,
    },
    disposalSitesDisposableDatesText: {
      type: String,
      required: true,
    },
    errorMessages: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  data(): DataType {
    return {
      isDisposablePeriodMultipleDialogActive: false,
    };
  },
  computed: {
    disposablePeriodItems(): DisposablePeriodItem[] {
      return this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
        const disposalSite = this.disposalSiteEntities.find(
          (disposalSite) => disposalSite.id === orderAssignedDisposalSite.disposalSiteId
        );
        if (disposalSite === undefined)
          rinLogger.warn(
            'disposalSite not found in masters. disposalSiteId: ',
            orderAssignedDisposalSite.disposalSiteId
          );
        return {
          ...orderAssignedDisposalSite,
          // NOTE: store に存在しない処分場が出てくることはほぼありえないが、 type guard が必要
          name: disposalSite ? disposalSite.name : '不明な処分場',
        };
      });
    },
    // NOTE: 指定処分場が複数の場合は簡易なテキストにする
    disposablePeriodMultipleText(): string {
      return this.disposablePeriodItems
        .map(
          (disposablePeriodItem) =>
            disposablePeriodItem.name +
            ' ' +
            formatPeriod(disposablePeriodItem.disposablePeriodStart, disposablePeriodItem.disposablePeriodEnd)
        )
        .join(', ');
    },
  },
  methods: {
    formatPeriod,
    onUpdateDisposablePeriodSingle(start: number, end: number): void {
      const currentDisposablePeriod: DisposalSiteDisposablePeriod = {
        disposalSiteId: this.orderAssignedDisposalSites[0].disposalSiteId,
        disposablePeriodStart: start,
        disposablePeriodEnd: end,
      };
      this.$emit('change', [currentDisposablePeriod]);
    },
    onUpdateDisposablePeriodMultiple(disposablePeriods: DisposalSiteDisposablePeriod[]): void {
      this.$emit('change', disposablePeriods);
    },
  },
});
