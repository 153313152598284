
import Vue, { PropType } from 'vue';
import RDialog from '~/components/common/r-dialog/RDialog.vue';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';
import { DisposablePeriodItem } from './RDisposalSitesDisposablePeriod.vue';
import { OrderDisposalSiteAssignmentType } from '~/framework/domain/typeAliases';

type DataType = {
  localDisposablePeriodItems: DisposablePeriodItem[];
  listenerDisposer: Maybe<() => void>;
};

enum EventTypes {
  Close = 'close',
  Update = 'update',
}

export default Vue.extend({
  name: 'RDisposalSiteDisposablePeriodMultipleDialog',
  components: {
    RDialog,
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    disposalSiteAssignmentType: {
      type: String as PropType<OrderDisposalSiteAssignmentType>,
      required: true,
    },
    disposablePeriodItems: {
      type: Array as PropType<DisposablePeriodItem[]>,
      required: true,
    },
    disposalSitesDisposableDatesText: {
      type: String,
      required: true,
    },
  },
  data(): DataType {
    return {
      localDisposablePeriodItems: this.disposablePeriodItems,
      listenerDisposer: undefined,
    };
  },
  mounted() {
    if (this.isDialogActive) this.createKeyboardEventListener();
  },
  beforeDestroy() {
    this.disposeKeyboardEventListener();
  },
  methods: {
    /**
     * Close dialog when `Esc` is pressed.
     * Stop propagation of all keyboard key press events to parents.
     */
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        this.onClickClose();
      }
      context.stop();
    },
    onClickClose() {
      this.$emit(EventTypes.Close);
    },
    onClickComplete() {
      this.$emit(
        'update',
        this.localDisposablePeriodItems.map((item) => {
          return {
            disposalSiteId: item.disposalSiteId,
            disposablePeriodStart: item.disposablePeriodStart,
            disposablePeriodEnd: item.disposablePeriodEnd,
          };
        })
      );
    },
    createKeyboardEventListener() {
      const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
        this.onKeydown,
        KeyboardEventPriority.Dialog
      );
      this.listenerDisposer = () => {
        keyboardEventListenerDisposer.dispose();
      };
    },
    disposeKeyboardEventListener() {
      if (this.listenerDisposer !== undefined) this.listenerDisposer();
    },
  },
});
