import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { Maybe, PersistentId, WithoutId } from '~/framework/typeAliases';
import * as List from '~/framework/application/masters/generation-site/list';
import {
  generationSite$getByKeywordsSymbol,
  ICondition,
  generationSite$createSymbol,
  ICreateData,
  generationSite$updateSymbol,
  IUpdateData,
  generationSite$getByIdsSymbol,
} from '~/framework/server-api/masters/generationSite';
import { mapData } from '~/framework/core/mapper';
import * as Selection from '~/framework/application/masters/generation-site/selection';
import { GenerationSiteMapper } from '~/framework/domain/masters/generation-site/generationSiteMapper';
import { GenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';

import { AggregatedGenerationSiteEntity } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteEntity';
import { IGenerationSiteFactory } from '~/framework/factories/masters/generationSiteFactory';
import { SoftDeleteStatus } from '~/framework/domain/typeAliases';

export const generationSiteSymbol = Symbol('generationSiteSymbol');

export class GenerationSiteApplicationService implements List.ISearch, Selection.ISearchSelection {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  private readonly generationSiteFactory: IGenerationSiteFactory;

  constructor(store: Store, serverApis: ServerApiManager, generationSiteFactory: IGenerationSiteFactory) {
    this.store = store;
    this.serverApis = serverApis;
    this.generationSiteFactory = generationSiteFactory;
  }

  async create(data: ICreateData): Promise<AggregatedGenerationSiteEntity> {
    const generationSite$getByIds = this.serverApis.get(generationSite$getByIdsSymbol);
    const generationSite$createApi = this.serverApis.get(generationSite$createSymbol);

    const [createResult] = await generationSite$createApi.create([data]);
    const [generationSiteData] = await generationSite$getByIds.getByIds([createResult.id]);

    const [entity] = await this.generationSiteFactory.buildByData([generationSiteData]);
    return entity;
  }

  async update(data: IUpdateData): Promise<AggregatedGenerationSiteEntity> {
    const generationSite$getByIds = this.serverApis.get(generationSite$getByIdsSymbol);
    const generationSite$updateApi = this.serverApis.get(generationSite$updateSymbol);

    const [updateResult] = await generationSite$updateApi.update([data]);
    const [generationSiteData] = await generationSite$getByIds.getByIds([updateResult]);

    const [entity] = await this.generationSiteFactory.buildByData([generationSiteData]);
    return entity;
  }

  async delete(id: PersistentId): Promise<AggregatedGenerationSiteEntity> {
    const generationSite$getByIds = this.serverApis.get(generationSite$getByIdsSymbol);
    const generationSite$updateApi = this.serverApis.get(generationSite$updateSymbol);
    const [data] = await generationSite$getByIds.getByIds([id]);
    const updateData: IUpdateData = {
      id: data.id,
      clientId: data.clientId,
      name: data.name,
      nameRuby: data.nameRuby,
      zipCode: data.zipCode,
      address1: data.address1,
      address2: data.address2,
      address3: data.address3,
      address4: data.address4,
      latitude: data.latitude,
      longitude: data.longitude,
      bannedDriverIds: data.bannedDriverIds,
      defaultAssignedDriverId: data.defaultAssignedDriverId,
      defaultAssignedDisposalSiteId: data.defaultAssignedDisposalSiteId,
      defaultAssignableCarTypeIds: data.defaultAssignableCarTypeIds,
      defaultAssignedCarId: data.defaultAssignedCarId,
      defaultAvoidHighways: data.defaultAvoidHighways,
      defaultDurationAtEntrance: data.defaultDurationAtEntrance,
      restPeriodStart: data.restPeriodStart,
      restPeriodEnd: data.restPeriodEnd,
      note: data.note,
      attachmentsToAdd: [],
      attachmentsToRemove: [],
      isAddressComplete: data.isAddressComplete,
      defaultCollectablePeriodTemplateId: data.defaultCollectablePeriodTemplateId,
      defaultCollectablePeriodStart: data.defaultCollectablePeriodStart,
      defaultCollectablePeriodEnd: data.defaultCollectablePeriodEnd,
      defaultPreloadStatus: data.defaultPreloadStatus,
      defaultRouteCollectionAllowed: data.defaultRouteCollectionAllowed,
      defaultIsFixedArrivalTimeReportNeeded: data.defaultIsFixedArrivalTimeReportNeeded,
      defaultMarginTypeOfFixedArrivalTime: data.defaultMarginTypeOfFixedArrivalTime,
      defaultMarginOfFixedArrivalTime: data.defaultMarginOfFixedArrivalTime,
      noteForOffice: data.noteForOffice,
      status: SoftDeleteStatus.Deleted, // NOTE これが大事
    };
    await generationSite$updateApi.update([updateData]);
    const [generationSite] = await this.generationSiteFactory.buildByData([
      {
        ...updateData,
        code: undefined, // コードが設定されている可能性はあるが、返却値を利用しないため undefined を入れておく。本来的には delete では Entity を返却しないようにしたい
        attachments: data.attachments,
        createdBy: data.createdBy,
        createdAt: data.createdAt,
      },
    ]);
    return generationSite;
  }
  // endregion

  // region List
  async search(first: number, after: Maybe<string>, condition: ICondition): Promise<List.IList> {
    const generationSite$getByKeywordsApi = this.serverApis.get(generationSite$getByKeywordsSymbol);
    const result = await generationSite$getByKeywordsApi.getByKeywords(first, after, condition);
    const entities = await this.generationSiteFactory.buildByData(result.edges.map((edge) => edge.node));
    const output: List.IList = {
      pageInfo: result.pageInfo,
      totalCount: result.totalCount,
      items: entities.map((entity, index) => {
        return {
          entity,
          cursor: result.edges[index].cursor,
        };
      }),
    };
    return output;
  }
  // endregion

  // region Selection
  async searchSelection(first: number, after: Maybe<string>, condition: ICondition): Promise<Selection.IList> {
    const generationSite$getByKeywordsApi = this.serverApis.get(generationSite$getByKeywordsSymbol);
    const generationSiteMapper = new GenerationSiteMapper();
    const result = await generationSite$getByKeywordsApi.getByKeywords(first, after, condition);
    const entities = generationSiteMapper.map(
      result.edges.map(({ node }) => node)
    ) as unknown as GenerationSiteEntity[];
    const output: Selection.IList = {
      pageInfo: result.pageInfo,
      totalCount: result.totalCount,
      items: entities.map((entity, index) => {
        return {
          entity,
          cursor: result.edges[index].cursor,
        };
      }),
    };
    return output;
  }
  // endregion

  // region Import
  async createMultiple(data: WithoutId<ICreateData>[]): Promise<GenerationSiteEntity[]> {
    const generationSite$createApi = this.serverApis.get(generationSite$createSymbol);
    const generationSiteMapper: GenerationSiteMapper = new GenerationSiteMapper();
    const result = await generationSite$createApi.create(data);
    const entities = generationSiteMapper.map(
      data.map((aData, index) => {
        return {
          ...aData,
          code: undefined, // 新規作成時はコード設定できないので undefined を入れる
          attachments: [],
          ...result[index],
        };
      })
    );
    return entities;
  }

  async updateMultiple(updateData: IUpdateData[]): Promise<GenerationSiteEntity[]> {
    const generationSite$updateApi = this.serverApis.get(generationSite$updateSymbol);
    const generationSite$getByIdsApi = this.serverApis.get(generationSite$getByIdsSymbol);
    const generationSiteMapper: GenerationSiteMapper = new GenerationSiteMapper();
    // NOTE data 内には createdAt や createdBy がなく面倒なので致し方なくやっている
    const [data] = await Promise.all([
      generationSite$getByIdsApi.getByIds(updateData.map((aData) => aData.id)),
      generationSite$updateApi.update(updateData),
    ]);
    const dataMap = mapData(data, 'id');
    const entities = generationSiteMapper.map(
      updateData.map((aData) => {
        const deficientData = dataMap.getOrError(aData.id);
        return {
          ...aData,
          code: undefined, // コードが設定されている可能性はあるが、返却値を利用しないため undefined を入れておく。本来的には update では Entity を返却しないようにしたい
          attachments: deficientData.attachments,
          createdAt: deficientData.createdAt,
          createdBy: deficientData.createdBy,
        };
      })
    );
    return entities;
  }

  // endregion

  async getByIds(ids: string[]): Promise<AggregatedGenerationSiteEntity[]> {
    const generationSite$getByIds = this.serverApis.get(generationSite$getByIdsSymbol);
    const data = await generationSite$getByIds.getByIds(ids);
    const entities = this.generationSiteFactory.buildByData(data);
    return entities;
  }

  async getById(id: PersistentId): Promise<GenerationSiteEntity> {
    const generationSiteMapper: GenerationSiteMapper = new GenerationSiteMapper();

    const generationSite$getByIdsApi = this.serverApis.get(generationSite$getByIdsSymbol);
    const [generationSite] = await generationSite$getByIdsApi.getByIds([id]);

    return generationSiteMapper.mapSingle(generationSite);
  }
}
