import { AbortedError, AlreadyCheckingError, CancelledByUserError, CancelledError } from './errors';
import { IOrderAcceptanceCheckResult, OrderAcceptanceCheckResult } from './orderAcceptanceResult';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { Ports } from '~/framework/core/ports';
import { IDisposable } from '~/framework/core/disposable';
import { Maybe, MaybeWeakRef, PersistentId } from '~/framework/typeAliases';
import { wait } from '~/framework/async';
import {
  OrderAcceptanceCheckStatus,
  OrderStatus,
  PreloadStatus,
  OrderDisposalSiteAssignmentType,
  MarginType,
  OrderSchedulingPriority,
} from '~/framework/domain/typeAliases';
import {
  ICreateApi,
  ICreateData,
  orderAcceptanceCheck$createSymbol,
} from '~/framework/server-api/schedule/order/order-acceptance-check/create';
import { OrderAcceptanceCheckMapper } from '~/framework/domain/schedule/order/order-acceptance-check/orderAcceptanceCheckMapper';
import { OrderAcceptanceCheckEntity } from '~/framework/domain/schedule/order/order-acceptance-check/orderAcceptanceCheckEntity';
import {
  IGetByIdsApi,
  orderAcceptanceCheck$getByIdsSymbol,
} from '~/framework/server-api/schedule/order/order-acceptance-check/getByIds';
import {
  CreateGenerationSiteTaskInput,
  CreateIrregularTaskInput,
  CreateOrderAssignableDriversAndNumInput,
  CreateOrderDisposalSitesAndTypeInput,
  OrderPlanInput,
} from '~/framework/server-api/typeAliases';
import { OrderAcceptanceCheckId } from '~/framework/constants';
import {
  RawInconsistencyJsonObject,
  RawInfeasibilityJsonObject,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { IScheduleSolutionStatus } from '~/framework/server-api/user-setting/userSetting';
import { IInfeasibility } from '~/pages/schedule/infeasibility';
import { IInconsistency } from '~/pages/schedule/inconsistency';
import { IScheduleFactory } from '~/framework/factories/schedule/scheduleFactory';

import { IOrderRoutingGroup } from '~/framework/server-api/schedule/order/orderRoutingGroup';

export const orderAcceptanceCheckSymbol = Symbol('orderAcceptanceCheck');

export interface IOrderAcceptanceCheckData {
  /**
   * 既存の受注はその ID、なければ undefined
   */
  id: Maybe<string>;
  date: Maybe<Date>;
  plan: OrderPlanInput;
  clientId: string;
  orderGroupId: string;
  generationSiteId: string;
  collectablePeriodTemplateName: string | undefined;
  collectablePeriodStart: number | undefined;
  collectablePeriodEnd: number | undefined;
  generationSiteTasks: CreateGenerationSiteTaskInput[] | undefined;
  irregularTasks: CreateIrregularTaskInput[] | undefined;
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  preloadStatus: PreloadStatus;
  unloadDate: Date | undefined;
  assignedBaseSiteId: string | undefined;
  // TODO: 処分場の入退場時間の後続リリースで削除
  assignedDisposalSiteIds: string[];
  // TODO: 処分場の入退場時間の後続リリースで削除
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  assignedDisposalSitesAndType: CreateOrderDisposalSitesAndTypeInput;
  assignableDriversAndNum: CreateOrderAssignableDriversAndNumInput;
  assignedCarId: string | undefined;
  assignableCarTypeIds: string[];
  // TODO: carNum に置き換わるので削除する
  minAssignedCarNum: number;
  // TODO: carNum に置き換わるので削除する
  maxAssignedCarNum: number;
  carNum: number;
  note: string | undefined;
  noteForAssignedDriver: string | undefined;
  avoidHighways: boolean;
  includeFollowingRecurringOrders: boolean | undefined;
  fixedArrivalTime: number | undefined;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  checkItemIds: string[];
  routingGroup: IOrderRoutingGroup | undefined;
  fixedDisplayOnReservation: boolean;
  fixedDisplayOnReservationName: string | undefined;
  schedulingPriority: OrderSchedulingPriority;
  status: OrderStatus;
  // ウェブ依頼の受注の瞬間チェックの場合はreservationIdが必要で、その他の受注の場合は必要はない
  reservationId: Maybe<string>;
}

export interface IOrderAcceptanceCheckPresenter {
  updateStatus(isChecking: boolean, estimatedFinishAt: Maybe<Date>, ordersNum: Maybe<number>): void;
}

export class OrderAcceptanceCheckApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  private readonly port: Ports<IOrderAcceptanceCheckPresenter>;
  private readonly mapper: OrderAcceptanceCheckMapper;
  private readonly getByIdsApi: IGetByIdsApi;
  private readonly createApi: ICreateApi;
  private readonly scheduleFactory: IScheduleFactory;
  /**
   * この時間ポーリングしたら異常が発生しているものとして abort する。
   * 現状、閾値は仮で5分とする。
   * @private
   */
  private readonly abortingThreshold: number = 1000 * 60 * 5;
  private readonly pollInterval: number = 1000 * 1;
  private checkingOrder: Maybe<IOrderAcceptanceCheckData>;
  private cancelled: Maybe<boolean>;
  private checkStartedAt: Maybe<Date>;
  private estimatedFinishAt: Maybe<Date> = undefined;
  private ordersNum: Maybe<number> = undefined;
  private isChecking: boolean = false;

  constructor(store: Store, serverApis: ServerApiManager, scheduleFactory: IScheduleFactory) {
    this.store = store;
    this.serverApis = serverApis;
    this.port = new Ports();
    this.mapper = new OrderAcceptanceCheckMapper(this.store.schedule.orderAcceptanceCheck);
    this.getByIdsApi = this.serverApis.get(orderAcceptanceCheck$getByIdsSymbol);
    this.createApi = this.serverApis.get(orderAcceptanceCheck$createSymbol);
    this.scheduleFactory = scheduleFactory;
  }

  addPresenter(presenter: MaybeWeakRef<IOrderAcceptanceCheckPresenter>): IDisposable {
    return this.port.add(presenter);
  }

  cancel(): void {
    this.cancelled = true;
  }

  async checkOrderAcceptance(order: IOrderAcceptanceCheckData): Promise<IOrderAcceptanceCheckResult> {
    if (this.isChecking) {
      // 少なくともこのクラスでは同時にチェックする事を想定しない
      throw new AlreadyCheckingError();
    }
    this.isChecking = true;
    this.outputStatus();

    const orderId = order.id ?? OrderAcceptanceCheckId;
    const orderAcceptanceCheckData: ICreateData = {
      ...order,
      id: orderId,
      attachmentsToAdd: [],
      attachmentsToRemove: [],
    };

    this.checkStartedAt = new Date();
    this.checkingOrder = order;
    const data = await this.createApi.create(orderAcceptanceCheckData);
    const entity = this.mapper.mapSingle({ ...data, ordersNum: undefined, estimatedFinishAt: undefined });

    // いきなり結果が出ている場合
    if (entity.status === OrderAcceptanceCheckStatus.Failed || entity.status === OrderAcceptanceCheckStatus.Finished) {
      this.reset();
      this.outputStatus();
      return new OrderAcceptanceCheckResult(orderId, entity.acceptanceData!);
    }

    // まだ結果が出ていない場合にはポーリングして結果を取得
    return await this.poll(orderId, entity);
  }

  async getOrderAcceptanceCheckInfeasibilities(
    infeasibilityDatas: RawInfeasibilityJsonObject[],
    input: IOrderAcceptanceCheckData,
    scheduleSolutionStatus?: Maybe<IScheduleSolutionStatus>
  ): Promise<IInfeasibility[]> {
    const orderId = input.id ?? OrderAcceptanceCheckId;
    const data: ICreateData = {
      ...input,
      id: orderId,
      attachmentsToAdd: [],
      attachmentsToRemove: [],
    };
    return await this.scheduleFactory.buildOrderAcceptanceCheckInfeasibilities(
      infeasibilityDatas,
      data,
      scheduleSolutionStatus
    );
  }

  async getOrderAcceptanceCheckInconsistencies(
    inconsistencyData: RawInconsistencyJsonObject[],
    scheduleSolutionStatus?: Maybe<IScheduleSolutionStatus>
  ): Promise<IInconsistency[]> {
    return await this.scheduleFactory.buildOrderAcceptanceCheckInconsistencies(
      inconsistencyData,
      scheduleSolutionStatus
    );
  }

  async getById(id: PersistentId): Promise<OrderAcceptanceCheckEntity> {
    const [data] = await this.getByIdsApi.getByIds([id]);
    return this.mapper.mapSingle(data);
  }

  private outputStatus(): void {
    this.port.output('updateStatus', this.isChecking, this.estimatedFinishAt, this.ordersNum);
  }

  /**
   * 結果を返して次の問い合わせをしてもいい状態になったら必ず呼ぶ事
   * @private
   */
  private reset(): void {
    this.isChecking = false;
    this.checkingOrder = undefined;
    this.cancelled = undefined;
    this.checkStartedAt = undefined;
    this.estimatedFinishAt = undefined;
    this.ordersNum = undefined;
  }

  /**
   * まだ結果が出ておらず、しばらく監視しなければいけない場合に
   * ポーリングして結果を取得する
   * @private
   */
  private async poll(orderId: PersistentId, entity: OrderAcceptanceCheckEntity): Promise<IOrderAcceptanceCheckResult> {
    while (true) {
      // ユーザーキャンセルの指示が入っていればキャンセル
      if (this.cancelled) {
        this.reset();
        this.outputStatus();
        throw new CancelledByUserError();
      }
      // 必要以上に時間がかかっており、強制中止の閾値を超えている場合には中止
      if (this.checkStartedAt !== undefined) {
        const now = new Date();
        if (this.abortingThreshold <= now.getTime() - this.checkStartedAt.getTime()) {
          this.reset();
          this.outputStatus();
          throw new AbortedError();
        }
      }

      // 一定時間待ってポーリング
      await wait(this.pollInterval);
      const [data] = await this.getByIdsApi.getByIds([entity.persistentId]);
      this.mapper.mapSingle(data);

      if (entity.status === OrderAcceptanceCheckStatus.Cancelled) {
        // サーバー側でキャンセルされている場合
        this.reset();
        this.outputStatus();
        throw new CancelledError();
      } else if (entity.status === OrderAcceptanceCheckStatus.Running) {
        // Running になった段階で estimatedFinishAt と ordersNum は埋められている
        this.estimatedFinishAt = entity.estimatedFinishAt;
        this.ordersNum = entity.ordersNum;
        this.outputStatus();
      } else if (
        entity.status === OrderAcceptanceCheckStatus.Finished ||
        entity.status === OrderAcceptanceCheckStatus.Failed
      ) {
        // 結果が出ていたらそれを返す
        this.reset();
        this.outputStatus();
        return new OrderAcceptanceCheckResult(orderId, entity.acceptanceData!);
      }
    }
  }
}
